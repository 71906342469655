h1 {
  color: var(--color-primary-4);
}

h2 {
  color: var(--color-white);
}

h3 {
  color: var(--color-primary-4);
}

.informatif .from-wysiwyg h5 {
  background: #81b1ac !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
}

.heading,
.accueil .heading {
  background: var(--color-primary-l);
}

.heading .curve::after {
  background: var(--color-primary-l);
}

.header,
.breadcrumb {
  background: var(--color-primary-l);
}

.header .header-menu .menu li a {
  color: var(--color-primary-4) !important;
}

.header .header-menu .menu li a:hover {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white) !important;
}

.header .header-menu .menu li.hover a,
.header .header-menu .menu li.hoveractive a {
  color: var(--color-white) !important;
}

.accueil .heading .header-logo img {
  max-width: 280px;
  position: relative;
  width: 100%;
}

.accueil .text-strong {
  color: var(--color-primary-5);
}

.accueil a.button-outline:hover {
  color: var(--color-primary-l) !important;
  background: var(--color-primary-2) !important;
}

a.button-primary {
  color: var(--color-white) !important;
  background-color: var(--color-primary-1);
}

a.button-secondary:hover {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-l) !important;
}

.tabs a.link {
  height: 30px;
  padding: 0;
  margin: 15px;
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-primary-3) !important;
}

.tabs a.link:hover {
  color: var(--color-primary-4) !important;
}

.tabs a.link.active {
  background: linear-gradient(to right, var(--color-primary-4), var(--color-primary-4)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-primary-4) !important;
  cursor: default;
}

main.accueil {
  background-color: var(--color-primary-l);
}

.accueil .tabs a.link {
  color: var(--color-primary-5) !important;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-5), var(--color-primary-5)), linear-gradient(to right, var(--color-white), var(--color-white));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  font-size: 17px;
  font-family: "Barlow Condensed";
  margin-right: 10px;
  font-weight: 600;
}

.accueil .tabs a.link.is-tab-el-active {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-white), var(--color-white));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-white) !important;
  cursor: default;
}

.accueil .tabs a.link:hover {
  color: var(--color-white) !important;
  background-size: 0 3px, 100% 3px;
}

.accueil .educform .titre h3,
.accueil .bafa .titre h3 {
  color: var(--color-primary-l);
}

.accueil .educform .titre h4,
.accueil .bafa .titre h4 {
  color: var(--color-primary-5) !important;
}

.accueil a.link {
  color: var(--color-primary-5);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-5), var(--color-primary-5));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
}

.accueil section.sejour::before {
  background: var(--color-primary-4) !important;
}

.accueil section.sejour::after {
  background: var(--color-primary-4) !important;
}

.accueil section.secteur2.enseignant,
.accueil section.secteur4.enseignant {
  background-color: var(--color-primary) !important;
}

.accueil section.enseignant::before {
  background: var(--color-primary) !important;
}

.accueil section.enseignant::after {
  background: var(--color-primary) !important;
}

section.aroevens.equipe.team {
  background: #eff6f5;
  padding: 200px 20px 0px 20px;
  margin-top: -120px;
}

.equipe .slider-nav-dark .vignettes-nav.swiper-button-disabled {
  color: #c3d2d0;
}

section.aroevens.equipe.team .py-80 {
  padding-top: 80px !important;
  padding-bottom: 0px !important;
}

// .accueil section.equipe::after {
//   border-radius: 50%;
//   height: 100px;
//   bottom: -150px;
//   transform: translate(-5%, -100%);
//   -ms-transform: translate(-5%, -100%);
//   -webkit-transform: translate(-5%, -100%);
//   content: "";
//   position: absolute;
//   width: 110%;
//   z-index: 9;
//   background: var(--color-primary-l) !important;
// }

.accueil section.sejour::before {
  background: var(--color-primary-l) !important;
}

.accueil section.sejour::after {
  background: var(--color-primary-l) !important;
}

.accueil section.secteur2.sejour,
.accueil section.secteur4.sejour {
  background-color: var(--color-primary-l) !important;
}

a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil .suggestions a.link,
.accueil .actualites a.link,
.accueil .recrutement a.link {
  color: var(--color-primary-2);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-2), var(--color-primary-2));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
}

.accueil .educform.suggestions a.link,
.accueil .bafa a.link {
  color: var(--color-primary-5);
}

.actualites .swiper-slide p {
  margin: 10px 0;
  line-height: 24px;
  font-family: "Sen", sans-serif;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  color: #68868b !important;
}

.accueil .suggestions a.link:hover,
.accueil .actualites a.link:hover,
.accueil .recrutement a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil a.button-primary {
  color: var(--color-white) !important;
  background-color: var(--color-primary);
}

.accueil .enseignant a.button-primary {
  color: var(--color-white) !important;
  background: var(--color-primary-4) !important;
}

.accueil .enseignant a.button-outline:hover {
  color: var(--color-white) !important;
  border: 2px solid var(--color-primary-4) !important;
  background: var(--color-primary-4) !important;
}

.accueil p {
  color: var(--color-primary-l);
}

.accueil .swiper-slide p,
.accueil .swiper-slide p i {
  color: var(--color-light-1);
}

.swiper-slide p,
.swiper-slide p i {
  color: var(--color-light-1);
}

.accueil .actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: var(--color-primary) !important;
}

.accueil .posts .titre h3 {
  color: var(--color-primary-l);
}

.accueil .testimonials {
  color: var(--color-primary-5);
}

.accueil section.equipe .titre h3 {
  color: var(--color-primary-4);
}

.accueil section.equipe .titre h4 {
  color: var(--color-primary-3) !important;
}

.vignette.mini .date {
  color: rgba(255, 255, 255, 0.8) !important;
}

.vignette.mini.bafa .date {
  color: rgba(255, 255, 255, 0.8) !important;
}

.encart-1 a.button-primary {
  background-color: var(--color-primary);
}

.encart a.button-primary.demande,
.encart .button-primary.demande {
  color: var(--color-white) !important;
  background-color: var(--color-primary-3);
}

.curve-after.curve-equipe .shape-fill {
  fill: #e3eae7;
}

.encart a.button-primary.devis,
.encart .button-primary.devis {
  color: var(--color-primary-3) !important;
  background-color: transparent;
  border: 2px solid var(--color-primary-3) !important;
}

.encart a.button-primary.devis:hover,
.encart .button-primary.devis:hover {
  background-color: var(--color-primary-3);
  border: 2px solid var(--color-primary-3) !important;
  color: var(--color-white) !important;
}

.accueil .curve-after.curve-equipe .shape-fill {
  fill: #e3eae7;
}

.accueil .temoignages h2 {
  color: var(--color-primary-5);
}

.accueil section.temoignages h5 {
  color: #59b09c;
}

.accueil section.temoignages .presta {
  color: var(--color-primary-5);
}

.accueil section.temoignages .orga {
  color: var(--color-primary-3);
}

.accueil .pagination.-avatars .pagination__item::before {
  border: 4px solid #59b09c;
}

.curve-before {
  position: relative;
  top: 120px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 99;
}

.curve-before svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-before .shape-fill {
  fill: var(--color-primary-l);
}

.curve-before-invert .shape-fill {
  fill: var(--color-primary-l);
}

.curve-after-invert .shape-fill {
  fill: var(--color-primary-l);
}

.curve-after .shape-fill {
  fill: var(--color-primary-l);
}

.curve-equipe {
  fill: var(--color-primary-l);
  bottom: 70px;
}

.curve-after.curve-equipe .shape-fill {
  fill: var(--color-primary-l);
}

.curve-after-invert.curve-recrutement .shape-fill {
  fill: var(--color-primary-4);
}

.curve-after.curve-actualites .shape-fill {
  fill: var(--color-primary-l);
}

.encart a.button-primary.demande,
.encart .button-primary.demande {
  color: var(--color-white) !important;
  background-color: var(--color-primary-4);
}

.encart a.button-primary.devis,
.encart .button-primary.devis {
  color: var(--color-primary-4) !important;
  background-color: transparent;
  border: 2px solid var(--color-primary-4) !important;
}

.encart a.button-primary.devis:hover,
.encart .button-primary.devis:hover {
  color: var(--color-white) !important;
  background-color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
}

main.actualite h2 {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: none;
}

main.actualite h2 i:before {
  color: var(--color-primary-3);
}

.sejourscolaire .resultats-multi h3 {
  color: var(--color-primary-4) !important;
}

.sejourscolaire .resultats-multi .vignette h3 {
  color: var(--color-white) !important;
}

.transports i {
  color: var(--color-bloc) !important;
}

.etape .actif {
  color: var(--color-primary-3) !important;
}

.etape .actif .rounded-full {
  background: var(--color-primary-5) !important;
}

.etape .inactif {
  color: #badccc !important;
}

.etape .inactif .rounded-full {
  background: #e1f0e8 !important;
  color: #badccc !important;
}

.etape .courant .rounded-full {
  background: var(--color-primary-4) !important;
}

.etape-line {
  border-bottom: 2px dashed var(--color-primary-5) !important;
}

section.aroevens.equipe.team .nom {
  margin-top: 16px;
  line-height: 24px;
}

section.equipe .nom,
section.equipe .info {
  color: var(--color-primary-3);
}

section.equipe .adresse,
section.equipe p {
  color: var(--color-primary-4);
}

section.equipe .bubble.team {
  height: 150px;
  width: 160px;
  clip-path: polygon(
    55.11% 0.032%,
    55.11% 0.032%,
    61.042% 0.627%,
    66.711% 1.991%,
    72.077% 4.072%,
    77.1% 6.816%,
    81.739% 10.169%,
    85.956% 14.079%,
    89.711% 18.491%,
    92.962% 23.353%,
    95.672% 28.611%,
    97.799% 34.211%,
    97.799% 34.211%,
    99.211% 39.84%,
    99.911% 45.493%,
    99.918% 51.116%,
    99.247% 56.651%,
    97.919% 62.044%,
    95.948% 67.239%,
    93.354% 72.18%,
    90.154% 76.812%,
    86.366% 81.08%,
    82.006% 84.926%,
    82.006% 84.926%,
    76.571% 88.814%,
    70.825% 92.227%,
    64.838% 95.09%,
    58.682% 97.324%,
    52.43% 98.85%,
    46.152% 99.592%,
    39.92% 99.47%,
    33.807% 98.407%,
    27.883% 96.326%,
    22.22% 93.147%,
    22.22% 93.147%,
    16.667% 88.747%,
    11.883% 83.695%,
    7.883% 78.085%,
    4.683% 72.013%,
    2.3% 65.574%,
    0.748% 58.864%,
    0.044% 51.977%,
    0.203% 45.011%,
    1.241% 38.059%,
    3.174% 31.217%,
    3.174% 31.217%,
    5.829% 25.22%,
    9.281% 19.84%,
    13.443% 15.084%,
    18.229% 10.962%,
    23.549% 7.483%,
    29.317% 4.654%,
    35.445% 2.487%,
    41.845% 0.988%,
    48.429% 0.167%,
    55.11% 0.032%
  );
  margin: 0;
  background-color: #cadedb !important;
}

section.equipe .child.withhover:hover .bubble.team {
  background-color: #015f59 !important;
  opacity: 0.85;
}

.child.active .bubble.team {
  background-color: #015f59 !important;
  opacity: 0.85;
}

main .informatif section.equipe .child:hover .nom,
main .informatif section.equipe .child:hover .info,
main .informatif section.equipe .child.active .nom,
main .informatif section.equipe .child.active .info {
  color: var(--color-primary-4);
  cursor: pointer;
}

.informatif section.equipe .bubble.team {
  // background-color: var(--color-primary-5) !important;
  background-color: #cadedb !important;
}

.informatif section.equipe .child.active .bubble.team {
  background-color: var(--color-primary-1) !important;
}

.informatif section.equipe .child.tous.active .bubble.team {
  background-color: var(--color-primary-1) !important;
}

.informatif section.equipe .child:hover .bubble.team {
  background-color: var(--color-primary-1) !important;
}

section.aroevens.equipe.team .child:hover .nom,
section.aroevens.equipe.team .child:hover .info {
  color: var(--color-primary-3);
  cursor: default;
}

.informatif section.equipe .child .bubble.team.photo {
  background-color: transparent !important;
}

// .informatif section.equipe .child.active .bubble.team.photo {
//   background-color: #015f59 !important;
//   opacity: 0.3;
// }

// .informatif section.equipe .child:hover .bubble.team.photo {
//   background-color: #015f59 !important;
//   opacity: 0.3;
// }

.accueil .enseignant {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-l);
}

.accueil .enseignant .divider {
  background-color: var(--color-primary-l);
  height: 9px;
  --svg: url(../img/backgrounds/line-wave.svg);
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  background-image: url(../img/backgrounds/line-wave.svg);
  margin: 50px 20px;
  width: calc(100% - 40px);
  opacity: 0.2;
}

.accueil .enseignant .titre h3 {
  color: var(--color-primary-5) !important;
}

.accueil .enseignant a.button-outline {
  color: var(--color-primary-5) !important;
  border: 2px solid var(--color-primary-5) !important;
}

.accueil .enseignant a.button-outline:hover {
  background: var(--color-primary-5) !important;
  border: 2px solid var(--color-primary-5) !important;
  color: var(--color-primary) !important;
}

.accueil .enseignant a.button-primary {
  color: var(--color-primary) !important;
  background: var(--color-primary-5) !important;
}

.accueil .enseignant a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-primary-3), var(--color-primary-3));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  color: var(--color-white);
  margin-bottom: 5px;
}

.accueil .enseignant a.link.white:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil .enseignant .bubble.conventionnement {
  background: var(--color-primary-3);
}

.accueil .enseignant .bubble.conventionnement .titre h4 {
  color: var(--color-primary) !important;
}

.accueil .enseignant .blob-back-enseignant {
  background: var(--color-primary-4) !important;
}

.accueil .enseignant .conventionnement a.button-outline {
  color: var(--color-white) !important;
  border: 2px solid var(--color-white) !important;
}

.accueil .enseignant .conventionnement a.button-outline:hover {
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-white) !important;
  background: var(--color-white) !important;
}

.breadcrumb {
  color: var(--color-secondary) !important;
}

.breadcrumb a.link.white {
  background: linear-gradient(to right, var(--color-secondary), var(--color-secondary)), linear-gradient(to right, var(--color-primary), var(--color-primary));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  display: inline !important;
  color: var(--color-secondary) !important;
  margin-bottom: 5px;
}

.breadcrumb a.link.white:hover {
  background-size: 0 3px, 100% 3px;
  color: var(--color-primary) !important;
}

.curve-after.curve-enseignant .shape-fill {
  fill: var(--color-primary);
}

.curve-after-invert.curve-suggestions .shape-fill {
  fill: var(--color-primary);
}

// .informatif h3 {
//   color: #3f938f !important;
// }

.select__options__button:hover {
  background-color: #cbddda !important;
  color: var(--color-primary-4) !important;
}

.select.mini .select__options__button:hover {
  background-color: #cbddda !important;
}

.processus-reservation a.button-secondary:hover {
  background-color: var(--color-primary-2) !important;
  color: var(--color-white) !important;
}

a.button-primary.secteur {
  background-color: var(--color-primary-3);
}

.badge-duree {
  color: white;
}

.footer {
  background-image: url(../img/backgrounds/bg-footer-fede.svg);
  color: var(--color-primary-5);
}

.accueil .footer {
  background-image: url(../img/backgrounds/bg-footer-fede.svg);
  color: var(--color-primary-5);
}

footer .container.contenu {
  background-color: var(--color-primary-1);
  margin-top: 100px;
}

.footer h5 {
  color: var(--color-primary-5);
}

.informatif .footer h5 {
  color: var(--color-primary-5) !important;
}

.footer p {
  color: var(--color-primary-5) !important;
}

.footer a.social {
  color: var(--color-primary-5) !important;
}

.footer a.social:hover {
  color: var(--color-white) !important;
}

.accueil footer a.button-primary {
  color: var(--color-primary-4) !important;
  background-color: var(--color-primary-5) !important;
}

footer a.button-primary {
  color: var(--color-primary-4) !important;
  background-color: var(--color-primary-5) !important;
}

footer a.button-primary:hover {
  color: var(--color-primary) !important;
  background-color: var(--color-primary-5) !important;
}

.footer li.liste-simple {
  color: var(--color-primary-5) !important;
}

.footer li.liste-simple:before {
  color: var(--color-primary-3) !important;
}

.footer-bas {
  background-color: var(--color-primary-1);
  background-image: url(../img/backgrounds/bg-footer-bas-fede.svg);
}

.footer a.social img {
  width: 36px;
  margin-right: 5px;
  margin-bottom: 5px;
  filter: brightness(0) saturate(100%) invert(95%) sepia(7%) saturate(700%) hue-rotate(107deg) brightness(86%) contrast(84%);
}

.footer a.social img:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(93%) sepia(11%) saturate(216%) hue-rotate(107deg) brightness(104%) contrast(90%);
}

.accueil .footer a.link {
  margin-right: 0;
}

.back-to-top i[class*=" fi-ss-"]:before {
  font-size: 50px;
  color: var(--color-primary-3);
}

.img-actu-presse .source {
  color: var(--color-primary-5) !important;
}

.tooltip-inner {
  background-color: var(--color-primary);
  box-shadow: none;
  opacity: 1 !important;
  color: var(--color-primary-l) !important;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .header .header-menu__content {
    background: var(--color-primary-l) !important;
  }
  .header.is-menu-opened {
    background-color: var(--color-primary-l) !important;
    border-bottom: 1px solid var(--color-primary-l);
  }
  .header .header-menu .menu .subnav > li > a {
    color: var(--color-primary-4) !important;
  }
  .header .header-menu .menu .subnav > li > a:hover {
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .mega-menu .mega .n2 a:not(.button-primary) {
    background-color: transparent !important;
    color: var(--color-primary) !important;
  }
  .mega-menu .mega .n2 a:not(.button-primary):hover {
    background-color: var(--color-primary-4) !important;
    color: var(--color-white) !important;
  }
  .mega-menu .mega .n1 a:not(.button-primary):hover {
    color: var(--color-white) !important;
    background-color: var(--color-ocre-dark) !important;
  }
  .mega-menu .mega a:not(.button-primary):hover {
    background-color: var(--color-primary-4) !important;
    color: var(--color-white) !important;
  }
  .mega-menu .mega .n3 a:not(.button-primary):hover {
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .mega-menu.recherche.v2 a:not(.button-primary):hover {
    background-color: transparent !important;
    color: var(--color-primary-3) !important;
  }
  .mega-menu .mega a.voirplus:hover {
    background-color: transparent !important;
  }

  // .header .header-menu .menu .subnav > li > a {
  //   color: var(--color-primary-4) !important;
  // }
  // .header .header-menu .menu .subnav > li > a:hover {
  //   background-color: var(--color-primary-3) !important;
  //   color: var(--color-white) !important;
  // }
  // .mega-menu .mega a:not(.button-primary) {
  //   color: var(--color-primary-4) !important;
  // }
  // .mega-menu .mega .n2 a:not(.button-primary) {
  //   background-color: transparent !important;
  //   color: #3e928d !important;
  // }
  // .mega-menu .mega .n1 a:not(.button-primary) {
  //   color: var(--color-primary-4) !important;
  // }
  // .mega-menu .mega .n3 a:not(.button-primary) {
  //   color: var(--color-primary-3) !important;
  // }
  // .mega-menu .mega .n1 a:not(.button-primary):hover {
  //   color: var(--color-white) !important;
  //   background-color: var(--color-primary-4) !important;
  // }
  // .mega-menu .mega a:not(.button-primary):hover {
  //   background-color: var(--color-primary-3) !important;
  //   color: var(--color-white) !important;
  // }
  // .mega-menu .mega .n3 a:not(.button-primary):hover {
  //   background-color: transparent !important;
  //   color: var(--color-primary-4) !important;
  // }
}

@media (max-width: 1199.98px) {
  .header.is-menu-opened {
    background-color: var(--color-primary-l) !important;
    border-bottom: 1px solid var(--color-bloc);
  }
  .header .header-menu__content {
    background: var(--color-primary-l) !important;
  }
  .header .header-menu .menu .subnav__backBtn a:hover {
    background-color: var(--color-primary) !important;
    color: var(--color-primary-l) !important;
    border-radius: 0;
    font-size: 20px;
  }
  .header .header-menu .menu li a:hover {
    background-color: var(--color-primary-4) !important;
    color: var(--color-primary-l) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
    // color: var(--color-primary-l) !important;
    color: #ebe6d5 !important;
  }
  .header .header-menu .menu .subnav li:first-child a {
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .subnav li:first-child a:hover {
    background: transparent !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a {
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li a:hover {
    // color: var(--color-primary-l) !important;
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a:hover {
    background: transparent !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a {
    color: var(--color-primary) !important;
  }
  .icon-menu {
    color: var(--color-primary-4) !important;
  }
}

@media (max-width: 575.98px) {
  .accueil section.equipe .parent {
    display: block;
    margin-top: 20px;
  }

  .equipe .child.libelle-region {
    margin-left: 0px;
  }

  .equipe .child.libelle-region ul li {
    font-size: 20px;
    color: var(--color-primary-4);
    font-family: "Barlow semi condensed";
    font-weight: 700;
    text-align: center;
    margin-top: 3px;
    text-align: left;
    line-height: 16px;
  }
}
