// .wave-container {
//     height: 40vh;
//     background-color: #20B2AA;
//     position: relative;
//     background-size: auto;
//     background-repeat: repeat no-repeat;
//     background-position: 42vw bottom;
//     background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  89' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 65L50 61C100 57 200 49 300 33C400 17 500 -7 600 1C700 9 800 49 900 65C1000 81 1100 73 1150 69L1200 65V90H1150C1100 90 1000 90 900 90C800 90 700 90 600 90C500 90 400 90 300 90C200 90 100 90 50 90H0V65Z' fill='%23FFF5EE'/></svg>");

//   }

//   .wave-container::before {
//     content: "";
//     width: 100%;
//     height: 93px;
//     position: absolute;
//     bottom: -0.3%;
//     left: 0;
//     background-size: auto;
//     background-repeat: repeat no-repeat;
//     background-position: 42vw bottom;
//     background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  89' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 65L50 61C100 57 200 49 300 33C400 17 500 -7 600 1C700 9 800 49 900 65C1000 81 1100 73 1150 69L1200 65V90H1150C1100 90 1000 90 900 90C800 90 700 90 600 90C500 90 400 90 300 90C200 90 100 90 50 90H0V65Z' fill='%23FFF5EE'/></svg>");
//   }

//   @media(max-width:850px) {
//     .wave-container::before {
//       height: 46.5px
//     }
//   }

/******* LAYOUT ********/

// .heading {
//   position: relative;
//   background: var(--color-primary);
// }

// .heading::after {
//   position: absolute;
//   content: "";
//   background: #2c3e50;
//   height: 100px;
//   border-bottom-left-radius: 50% 20%;
//   border-bottom-right-radius: 50% 20%;
// }

html {
  scroll-behavior: smooth;
}

.font-primary {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.login.full {
  background: var(--color-primary-d);
}

.no-page__title {
  font-size: 120px;
  font-weight: 600;
  line-height: 1.2;
}

main.login.full > section:last-of-type {
  padding-bottom: 0 !important;
}

main.login.full .header-logo img {
  max-width: 200px;
}

main.login.full .header-logo.federation .baseline {
  padding: 12px 7px;
  font-size: 22px;
  color: #c7dfdb;
  background: #3d858196;
  max-width: 200px;
  margin-top: 8px;
  text-align: center;
}

main.login.full a.link {
  color: var(--color-primary-3);
}

main.login.full .form-input textarea:focus,
main.login.full .form-input input:focus {
  background: #fff !important;
  outline: none;
  border: 2px solid white !important;
}

main.login.full a.button-primary:hover {
  background: var(--color-primary-3);
}

.bloc-login {
  // height: calc(100vh - 200px);
  // min-height: 800px;
  height: initial;
  min-height: initial;
}

.enseignant .bloc-login {
  height: calc(100vh - 200px);
  min-height: 1000px;
  padding-bottom: 100px !important;
}

main.login.full .bloc-login {
  align-items: start !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  min-height: initial;
  height: initial;
}

.switchversion {
  position: fixed;
  top: 180px;
  background: var(--color-terracotta);
  z-index: 99999;
  color: white;
  padding: 5px 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: top left;
}

main.has-sticky {
  overflow: initial;
}

.heading,
.accueil .heading {
  background: var(--color-primary);
}

// .informatif .heading {
//   padding-top: 140px !important;
// }

.header,
.breadcrumb {
  background: var(--color-primary);
}

.z-index-min {
  z-index: 0;
}

.z-index-med {
  z-index: 999;
}

.z-index-max {
  z-index: 999999;
}

a:hover {
  color: var(--color-primary-3);
}

.opacity-0 {
  opacity: 0 !important;
}

.preloader__wrap {
  border: none;
  box-shadow: none;
  background: none;
}

.preloader__icon {
  position: initial;
  animation-name: initial;
  animation-iteration-count: initial;
  animation-duration: initial;
  animation-timing-function: initial;
}

.back-to-top {
  bottom: 3em;
  opacity: 0.9;
  position: fixed;
  right: 4em;
  transition: opacity 200ms ease;
  z-index: 999999;
  color: var(--color-terracotta);
}

.back-to-top i[class*=" fi-ss-"]:before {
  font-size: 50px;
  color: var(--color-terracotta);
}

.back-to-top:hover {
  opacity: 1;
}

.at-top .back-to-top {
  opacity: 0;
  pointer-events: none;
}

.parallax {
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.accueil .carousel .overlay {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 100%);
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 20px 20px 20px 40px;
  position: relative;
}

.blob {
  background: var(--color-primary-4);
  width: 500px;
  height: 500px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 15s linear infinite;
  z-index: 999;
  top: 40px;
}

.blob-back {
  background: var(--color-primary);
  min-width: 500px;
  min-height: 500px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  position: absolute;
  z-index: 9;
  margin-left: 30px;
  top: 20px;
}

.searchbar.container {
  max-width: 960px;
}

.searchbar.container.slogan {
  padding-top: 120px;
  padding-bottom: 40px;
}

.searchbar .blob {
  height: 450px;
  width: 450px;
  top: 20px;
}

.searchbar .blob-back {
  min-width: 450px;
  min-height: 450px;
  top: 0;
}

.accueil .searchbar h1 {
  line-height: 60px;
  margin-top: 40px;
  margin-top: 0px !important;
  padding-right: 30px;
}

.accueil .text-strong {
  color: var(--color-ocre);
}

.blob-back-enseignant {
  background: #c17f06;
  min-width: 360px;
  min-height: 340px;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  position: absolute;
  z-index: 9;
  left: 20px;
  top: 10px;
}

.blob img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .blob {
// background: #3f3d56
//     url("")
//     center / 100% auto
// }

@keyframes morph {
  0%,
  100% {
    border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
}

.accueil h1 {
  color: var(--color-titre-light);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  text-transform: none;
  margin-top: 40px;
}

// .accueil .recherche .tabs a.link {
//   background: linear-gradient(to right, #ecb755, #ecb755),
//     linear-gradient(to right, var(--color-primary-2), var(--color-primary-2));
// }

.accueil .heading.parallax {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../img/hero/hero-region.png");
  background-position: left !important;
}

.accueil section.secteur1.bafa {
  position: relative;
  min-height: 800px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur1.educform {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  // margin-top: -70px;
  padding-top: 160px !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur2.sejour {
  background-image: none;
  margin-top: 0px;
  position: relative;
  background-color: var(--color-primary-4) !important;
  padding: 80px 20px;
}

.accueil section.secteur2.enseignant {
  position: relative;
  background-color: var(--color-ocre) !important;
  margin-top: 0px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fbf4e7 !important;
}

.accueil section.secteur3.bafa {
  position: relative;
  min-height: 500px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-top: 200px;
  padding-bottom: 200px;
}

.accueil section.secteur3.educform {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  padding-top: 160px;
  padding-bottom: 160px;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.accueil section.secteur4.sejour {
  background-image: none;
  padding-top: 0px;
  margin-top: 0px;
  position: relative;
  background-color: var(--color-primary-4) !important;
  padding: 80px 20px;
}

.accueil section.secteur4.enseignant {
  position: relative;
  background-color: var(--color-ocre) !important;
  // margin-top: -100px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fbf4e7 !important;
}

.accueil .curve-after.curve-suggestions {
  bottom: 0;
}

.accueil section.sejour::before {
  border-radius: 50%;
  height: 100px;
  top: 50px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-primary-4) !important;
}

.accueil section.sejour::after {
  border-radius: 50%;
  height: 100px;
  bottom: -150px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-primary-4) !important;
}

.accueil section.enseignant::before {
  border-radius: 50%;
  height: 150px;
  top: 80px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-ocre) !important;
}

.accueil section.enseignant::after {
  border-radius: 50%;
  height: 150px;
  bottom: -200px;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: 9;
  background: var(--color-ocre) !important;
}

.accueil section.bafa {
  position: relative;
  background: #fff;
  background-image: url("../img/gallery/bafa-6.jpg");
  // clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  min-height: 800px;
}

.accueil section.bafa.parallax {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/bafa-6.jpg");

  // background-image: linear-gradient(
  //     90deg,
  //     rgba(0, 105, 108, 0.5) 30%,
  //     rgba(0, 105, 108, 0) 100%
  //   ),
  //   url("../img/gallery/bafa-3.jpg");
}

.accueil .testimonials {
  font-size: 22px;
  white-space: pre-wrap;
}

.accueil .testimonials.slogan {
  font-size: 36px;
  line-height: 60px;
  padding-top: 20px;
}

.blur {
  backdrop-filter: blur(3px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
}

.accueil section.educform.parallax {
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0.1) 100%), url("../img/gallery/educform-1.jpg");
  min-height: 800px;
  // margin-top: -70px;
  padding-top: 160px !important;
}

.accueil section.temoignages .presta {
  color: var(--color-primary-2);
}

.accueil section.temoignages .orga {
  color: var(--color-primary-3);
}

.accueil section.temoignages .swiper-slide {
  background-color: inherit;
  // padding-bottom: 20px;
  width: inherit !important;
}

.accueil .pagination.-avatars .pagination__item {
  cursor: pointer;
}

.accueil .pagination.-avatars .pagination__item.is-active::before {
  opacity: 1;
}

.accueil .pagination.-avatars .pagination__item::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  border: 4px solid var(--color-primary-2);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.accueil .pagination i:before {
  font-size: 22px;
  color: var(--color-primary-3);
}

section.aroevens.equipe {
  background: #f0d9ae;
  padding: 200px 20px 80px 20px;
  margin-top: -120px;
}

section.aroevens.equipe h5 {
  background: var(--color-primary) !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 10px 0 5px 0;
  text-align: left;
}

section.aroevens.equipe h5.selection {
  background: #5f9c99 !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  margin: 10px 0 5px 0;
  text-align: left;
  max-width: 400px;
}

section.aroevens.equipe h5.selection .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  font-size: initial;
  color: white !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  background: transparent;
  justify-content: space-between;
}

section.aroevens.equipe h5.selection .accordion-button::after {
  width: auto;
  height: auto;
  background-image: none;
  font-family: "uicons-bold-straight" !important;
  content: "\f150";
  font-size: 16px;
  margin-left: 15px;
}

section.aroevens.equipe span.aroeven-contact {
  text-transform: none;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  display: block;
  padding: 0;
  margin: 5px 0;
  text-align: left;
  line-height: 26px;
}

section.aroevens.equipe .liste-aroevens {
  color: var(--color-primary-4);
}

// .informatif section.aroevens.equipe [data-anim^="slide-"],
// .informatif section.aroevens.equipe [data-anim-child^="slide-"] {
//   opacity: 1;
//   pointer-events: none;
//   transition: none;
//   animation: none;
// }

section.aroevens.equipe .liste-aroevens h3 {
  font-size: 30px;
  line-height: 32px;
  padding-bottom: 10px;
  margin-top: 25px;
}

.informatif section.aroevens.equipe .liste-aroevens h3 {
  font-size: 30px;
  line-height: 32px;
  padding-bottom: 10px;
  margin-top: 25px;
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
}

section.aroevens.equipe .liste-aroevens span.aroeven-contact {
  text-transform: none;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 17px;
  display: block;
  padding: 0;
  margin: 3px 0;
  text-align: left;
  line-height: 20px;
}

section.aroevens.equipe a.button-primary.web {
  color: var(--color-white) !important;
  background-color: #5f9c99;
  margin: 10px 0px;
  width: fit-content;
  padding: 16px 24px;
  line-height: 20px;
}

section.aroevens.equipe .liste-aroevens a.button-primary.web {
  color: var(--color-white) !important;
  background-color: #5f9c99;
  margin: 10px 0px;
  padding: 10px 15px;
  width: fit-content;
  line-height: 20px;
}

section.aroevens.equipe.fede {
  background: #e3eae7;
}

.informatif section.aroevens.equipe {
  background: none !important;
  padding: 0 !important;
  margin-top: 120px !important;
}

.informatif section.interlocuteurs.aroevens.equipe.team {
  padding-bottom: 60px !important;
}

.informatif section.interlocuteurs.aroevens.equipe.team .bubble-back.actif {
  background: var(--color-primary-4);
}

section.aroevens.equipe .liste-aroevens h5 {
  width: 100%;
}

section.aroevens.equipe.fede .libelle-region a.surbrillance {
  background: var(--color-primary-4);
  color: var(--color-white);
  padding: 2px 5px;
}

// .accueil section.equipe.fede .libelle-region a {
//   padding: 2px 5px;
//   line-height: 20px;
//   display: inline-block;
//   font-size: 17px;
// }

// .accueil section.equipe.fede .libelle-region a:hover {
//   background: var(--color-primary-4);
//   color: var(--color-white);
//   padding: 2px 5px;
// }

section.aroevens.equipe.fede .box {
  width: 40px;
  height: 40px;
}

section.aroevens.equipe.fede .box.surbrillance {
  background-image: url(../img/map/marker-hover.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  transform: scale(1.2);
}

section.aroevens.equipe.fede .box:hover {
  background-image: url(../img/map/marker-hover.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  -webkit-transition: background-image 0.2s ease;
  transition: background-image 0.2s ease;
  transform: scale(1.2);
}

section.aroevens.equipe .titre h3 {
  color: var(--color-ocre-dark);
}

section.aroevens.equipe .titre h4 {
  color: var(--color-ocre) !important;
}

.equipe .parent {
  display: flex;
  gap: 40px;
}

.equipe .child {
  // flex-basis: 100%;
}

.equipe .child.libelle-region {
  margin-left: 80px;
}

.equipe .child.libelle-region ul li {
  font-size: 20px;
  color: var(--color-primary-4);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 3px;
  text-align: left;
}

section.equipe .swiper-slide {
  background-color: transparent;
  width: 150px !important;
}

.cartefrance {
  position: relative;
  display: inline-block;
  min-width: 600px;
}

.cartefrance img {
  max-width: 100%;
  display: block;
}

.box {
  width: 40px;
  height: 40px;
  background-image: url(../img/map/marker.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
}

#pin1 {
  top: 29%;
  left: 36%;
}

#pin2 {
  top: 45%;
  left: 48%;
}

#pin3 {
  top: 45%;
  left: 48%;
}

#pin4 {
  top: 45%;
  left: 48%;
}

#pin5 {
  top: 35%;
  left: 28%;
}

#pin6 {
  top: 65%;
  left: 78%;
}

#pin7 {
  top: 45%;
  left: 68%;
}

#pin8 {
  top: 55%;
  left: 68%;
}

.box:hover > .pin-text {
  display: block;
}

.pin-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 75%;
  white-space: nowrap;
  display: none;
}

.pin-text h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-primary-3);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

section.equipe .bubble.team {
  height: 150px;
  width: 160px;
  clip-path: polygon(
    55.11% 0.032%,
    55.11% 0.032%,
    61.042% 0.627%,
    66.711% 1.991%,
    72.077% 4.072%,
    77.1% 6.816%,
    81.739% 10.169%,
    85.956% 14.079%,
    89.711% 18.491%,
    92.962% 23.353%,
    95.672% 28.611%,
    97.799% 34.211%,
    97.799% 34.211%,
    99.211% 39.84%,
    99.911% 45.493%,
    99.918% 51.116%,
    99.247% 56.651%,
    97.919% 62.044%,
    95.948% 67.239%,
    93.354% 72.18%,
    90.154% 76.812%,
    86.366% 81.08%,
    82.006% 84.926%,
    82.006% 84.926%,
    76.571% 88.814%,
    70.825% 92.227%,
    64.838% 95.09%,
    58.682% 97.324%,
    52.43% 98.85%,
    46.152% 99.592%,
    39.92% 99.47%,
    33.807% 98.407%,
    27.883% 96.326%,
    22.22% 93.147%,
    22.22% 93.147%,
    16.667% 88.747%,
    11.883% 83.695%,
    7.883% 78.085%,
    4.683% 72.013%,
    2.3% 65.574%,
    0.748% 58.864%,
    0.044% 51.977%,
    0.203% 45.011%,
    1.241% 38.059%,
    3.174% 31.217%,
    3.174% 31.217%,
    5.829% 25.22%,
    9.281% 19.84%,
    13.443% 15.084%,
    18.229% 10.962%,
    23.549% 7.483%,
    29.317% 4.654%,
    35.445% 2.487%,
    41.845% 0.988%,
    48.429% 0.167%,
    55.11% 0.032%
  );
  margin: 0;
  background: #e8c891;
}

.informatif section.equipe .bubble.team {
  height: 150px;
  width: 160px;
  clip-path: polygon(
    55.11% 0.032%,
    55.11% 0.032%,
    61.042% 0.627%,
    66.711% 1.991%,
    72.077% 4.072%,
    77.1% 6.816%,
    81.739% 10.169%,
    85.956% 14.079%,
    89.711% 18.491%,
    92.962% 23.353%,
    95.672% 28.611%,
    97.799% 34.211%,
    97.799% 34.211%,
    99.211% 39.84%,
    99.911% 45.493%,
    99.918% 51.116%,
    99.247% 56.651%,
    97.919% 62.044%,
    95.948% 67.239%,
    93.354% 72.18%,
    90.154% 76.812%,
    86.366% 81.08%,
    82.006% 84.926%,
    82.006% 84.926%,
    76.571% 88.814%,
    70.825% 92.227%,
    64.838% 95.09%,
    58.682% 97.324%,
    52.43% 98.85%,
    46.152% 99.592%,
    39.92% 99.47%,
    33.807% 98.407%,
    27.883% 96.326%,
    22.22% 93.147%,
    22.22% 93.147%,
    16.667% 88.747%,
    11.883% 83.695%,
    7.883% 78.085%,
    4.683% 72.013%,
    2.3% 65.574%,
    0.748% 58.864%,
    0.044% 51.977%,
    0.203% 45.011%,
    1.241% 38.059%,
    3.174% 31.217%,
    3.174% 31.217%,
    5.829% 25.22%,
    9.281% 19.84%,
    13.443% 15.084%,
    18.229% 10.962%,
    23.549% 7.483%,
    29.317% 4.654%,
    35.445% 2.487%,
    41.845% 0.988%,
    48.429% 0.167%,
    55.11% 0.032%
  );
  margin: 0 auto;
  margin-top: 6px;
  background: #e8c891;
}

.informatif section.equipe .bubble-back {
  position: relative;
  height: 150px;
  width: 160px;
  clip-path: polygon(
    55.11% 0.032%,
    55.11% 0.032%,
    61.042% 0.627%,
    66.711% 1.991%,
    72.077% 4.072%,
    77.1% 6.816%,
    81.739% 10.169%,
    85.956% 14.079%,
    89.711% 18.491%,
    92.962% 23.353%,
    95.672% 28.611%,
    97.799% 34.211%,
    97.799% 34.211%,
    99.211% 39.84%,
    99.911% 45.493%,
    99.918% 51.116%,
    99.247% 56.651%,
    97.919% 62.044%,
    95.948% 67.239%,
    93.354% 72.18%,
    90.154% 76.812%,
    86.366% 81.08%,
    82.006% 84.926%,
    82.006% 84.926%,
    76.571% 88.814%,
    70.825% 92.227%,
    64.838% 95.09%,
    58.682% 97.324%,
    52.43% 98.85%,
    46.152% 99.592%,
    39.92% 99.47%,
    33.807% 98.407%,
    27.883% 96.326%,
    22.22% 93.147%,
    22.22% 93.147%,
    16.667% 88.747%,
    11.883% 83.695%,
    7.883% 78.085%,
    4.683% 72.013%,
    2.3% 65.574%,
    0.748% 58.864%,
    0.044% 51.977%,
    0.203% 45.011%,
    1.241% 38.059%,
    3.174% 31.217%,
    3.174% 31.217%,
    5.829% 25.22%,
    9.281% 19.84%,
    13.443% 15.084%,
    18.229% 10.962%,
    23.549% 7.483%,
    29.317% 4.654%,
    35.445% 2.487%,
    41.845% 0.988%,
    48.429% 0.167%,
    55.11% 0.032%
  );
  margin: 0 auto;
  background: transparent;
}

.informatif section.equipe .bubble-back.actif {
  background: var(--color-primary-4);
}

section.aroevens.equipe.team .bubble-back {
  position: relative;
  height: 150px;
  width: 160px;
  clip-path: polygon(
    55.11% 0.032%,
    55.11% 0.032%,
    61.042% 0.627%,
    66.711% 1.991%,
    72.077% 4.072%,
    77.1% 6.816%,
    81.739% 10.169%,
    85.956% 14.079%,
    89.711% 18.491%,
    92.962% 23.353%,
    95.672% 28.611%,
    97.799% 34.211%,
    97.799% 34.211%,
    99.211% 39.84%,
    99.911% 45.493%,
    99.918% 51.116%,
    99.247% 56.651%,
    97.919% 62.044%,
    95.948% 67.239%,
    93.354% 72.18%,
    90.154% 76.812%,
    86.366% 81.08%,
    82.006% 84.926%,
    82.006% 84.926%,
    76.571% 88.814%,
    70.825% 92.227%,
    64.838% 95.09%,
    58.682% 97.324%,
    52.43% 98.85%,
    46.152% 99.592%,
    39.92% 99.47%,
    33.807% 98.407%,
    27.883% 96.326%,
    22.22% 93.147%,
    22.22% 93.147%,
    16.667% 88.747%,
    11.883% 83.695%,
    7.883% 78.085%,
    4.683% 72.013%,
    2.3% 65.574%,
    0.748% 58.864%,
    0.044% 51.977%,
    0.203% 45.011%,
    1.241% 38.059%,
    3.174% 31.217%,
    3.174% 31.217%,
    5.829% 25.22%,
    9.281% 19.84%,
    13.443% 15.084%,
    18.229% 10.962%,
    23.549% 7.483%,
    29.317% 4.654%,
    35.445% 2.487%,
    41.845% 0.988%,
    48.429% 0.167%,
    55.11% 0.032%
  );
  margin: 0 auto;
  background: transparent;
}

section.aroevens.equipe.team .bubble.team.photo {
  background-color: transparent !important;
}

section.aroevens.equipe.team .bubble-back.actif {
  background: var(--color-primary-4);
}

section.equipe .nom {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-ocre-dark);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  transition: 0.3s;
}

section.equipe .adresse {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-ocre-dark);
  font-family: "Barlow semi condensed";
  font-weight: 700;
  text-align: left;
  margin-top: 0;
  max-width: 200px;
  line-height: 20px;
}

section.equipe .info {
  color: var(--color-ocre);
  text-align: center;
  font-size: 16px;
  display: block;
  line-height: 20px;
  font-family: "Barlow semi condensed";
  margin: 0 auto;
  transition: 0.3s;
}

section.equipe p {
  color: var(--color-ocre);
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: "Barlow semi condensed";
  margin-top: 0;
}

.accueil .team img {
  width: 100px;
  bottom: -15px;
  position: relative;
}

.accueil .team.photo img {
  bottom: auto;
  top: -10px;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  min-width: 150px;
}

.accueil section.actualites {
  padding-bottom: 0px;
}

.accueil section.posts {
  background: var(--color-primary-4);
  padding: 180px 0;
}

.accueil section.recrutement {
  padding-bottom: 120px;
}

.accueil section.recrutement.last {
  padding-bottom: 300px;
}

.accueil section.temoignages {
  background: var(--color-primary-4);
  padding: 20px 0 100px 0;
  position: relative;
  color: var(--color-primary-2);
  padding-bottom: 200px;
}
.accueil section.temoignages h5 {
  color: var(--color-ocre);
  text-transform: uppercase;
  font-family: "Barlow semi condensed";
  font-size: 30px !important;
}

.accueil section.temoignages img.quotes {
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: calc(50% - 75px);
}

.accueil .tabs__controls {
  position: relative;
  overflow: initial;
  scroll-snap-type: none;
  white-space: nowrap;
}

.slider-nav .vignettes-nav {
  color: var(--color-primary-2);
}

.slider-nav .pagination.-dots.text-border {
  color: var(--color-primary-2);
}

.slider-nav .pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-2);
  transform: scale(1.3);
}

.slider-nav .vignettes-nav.swiper-button-disabled {
  color: var(--color-primary-4);
}

.slider-nav .vignettes-nav.arrow-right-hover:hover,
.slider-nav .vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-2);
  transform: translateX(5px) scale(1.25);
}

.slider-nav .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.slider-nav .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  transform: none;
  color: var(--color-primary-4);
}

.slider-nav-dark .vignettes-nav {
  color: var(--color-primary-4);
}

.slider-nav-dark .pagination.-dots.text-border {
  color: var(--color-primary-4);
}

.slider-nav-dark .pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-4);
  transform: scale(1.3);
}

.slider-nav-dark .vignettes-nav.swiper-button-disabled {
  color: var(--color-primary-2);
}

.slider-nav-dark .vignettes-nav.arrow-right-hover:hover,
.slider-nav-dark .vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-4);
  transform: translateX(5px) scale(1.25);
}

.slider-nav-dark .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.slider-nav-dark .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  transform: none;
  color: var(--color-primary-2);
}
.equipe .slider-nav-dark .vignettes-nav.swiper-button-disabled {
  color: #e1c89b;
}

.gallery-a #hero-video,
.gallery-a .hero-video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.equipe .slider-nav-dark .vignettes-nav.arrow-right-hover.swiper-button-disabled:hover,
.equipe .slider-nav-dark .vignettes-nav.arrow-left-hover.swiper-button-disabled:hover {
  color: #e1c89b;
}

.heading {
  background: var(--color-primary);
  margin-bottom: 4em;
  position: relative;
  color: #fff;
  padding-top: 4em;
}

.heading .curve {
  position: relative;
  display: block;
  height: 140px;
  bottom: -140px;
  margin-top: -100px;
  overflow: hidden;
}
.heading .curve::after {
  border-radius: 50%;
  height: 100px;
  bottom: 0;
  transform: translate(-5%, -100%);
  -ms-transform: translate(-5%, -100%);
  -webkit-transform: translate(-5%, -100%);
  content: "";
  position: absolute;
  width: 110%;
  z-index: -1;
  background: var(--color-primary);
}

.heading::after {
  // position: absolute;
  // content: "";
  // left: 50%;
  // z-index: 10;
  // width: 100px;
  // height: 100px;
  // border-radius: 50%;
  // background: inherit;
  // transform: translateX(-50%) translateY(50%);
  // bottom: 0px;
  // background-image: url("../img/backgrounds/bg-wave.svg");
}

.title {
  padding-top: 100px;
}

main > section:last-of-type {
  padding-bottom: 200px !important;
}

.sticky {
  position: sticky;
  top: 20px;
}

.bg-wave {
  background-color: var(--color-white) !important;
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-wave.svg");
  background-position: bottom;
  // background-position: 0 calc(0% - 500px);
}

.divider {
  background-color: var(--color-primary-5);
  height: 9px;
  --svg: url("../img/backgrounds/line-wave.svg");
  // --svg: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23453886' stroke-width='2' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  // --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 80 9.26'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23dce7e2; %7D %3C/style%3E%3C/defs%3E%3C!-- Generator: Adobe Illustrator 28.6.0, SVG Export Plug-In . SVG Version: 1.2.0 Build 709) --%3E%3Cg%3E%3Cg id='Calque_1'%3E%3Cpath class='cls-1' d='M79,0c-3.7,0-5.58,2.1-7.25,3.96-1.58,1.77-2.95,3.3-5.76,3.3s-4.17-1.53-5.76-3.3c-1.66-1.86-3.55-3.96-7.25-3.96s-5.58,2.1-7.24,3.96c-1.58,1.77-2.95,3.29-5.75,3.29s-4.17-1.53-5.76-3.29c-1.66-1.86-3.55-3.96-7.25-3.96s-5.58,2.1-7.24,3.96c-1.58,1.77-2.95,3.3-5.75,3.3s-4.17-1.53-5.75-3.29C6.58,2.1,4.7,0,1,0,.45,0,0,.45,0,1s.45,1,1,1c2.8,0,4.17,1.53,5.75,3.29,1.66,1.86,3.55,3.96,7.24,3.96s5.58-2.1,7.24-3.96c1.58-1.77,2.95-3.29,5.75-3.29s4.17,1.53,5.76,3.3c1.66,1.86,3.55,3.96,7.25,3.96s5.58-2.1,7.24-3.96c1.58-1.77,2.95-3.29,5.75-3.29s4.17,1.53,5.76,3.3c1.66,1.86,3.55,3.96,7.25,3.96s5.58-2.1,7.25-3.96c1.58-1.77,2.95-3.3,5.76-3.3.55,0,1-.45,1-1s-.45-1-1-1Z'/%3E%3Cpath class='cls-1' d='M1.36,2.01c-.23-.01-.46-.02-.71-.02-.23,0-.44,0-.65.02V0h1.36v2.01Z'/%3E%3Cpath class='cls-1' d='M80,2.01c-.23-.01-.46-.02-.71-.02-.23,0-.44,0-.65.02V0h1.36v2.01Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  background-image: url("../img/backgrounds/line-wave.svg");
  margin: 50px 0;
}

.accueil .enseignant .divider {
  background-color: var(--color-ocre-dark);
  height: 9px;
  --svg: url("../img/backgrounds/line-wave-ocre.svg");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  background-image: url("../img/backgrounds/line-wave-ocre.svg");
  margin: 50px 20px;
  width: calc(100% - 40px);
}

.separateur {
  color: var(--color-light-1);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.encart-2 h4 {
  font-size: 16px;
}

.encart .separateur .divider {
  width: 100%;
  margin: 12px 10px 10px 10px;
  height: 8px;
}

section.carte {
  position: relative;
}

// .encart {
//   position: sticky;
//   top: 120px;
//   margin-top: -275px;
//   height: calc(100vh - 150px);
//   overflow-y: auto;
// }

.encart {
  position: sticky;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  right: 15px;
  width: 360px;
  float: right;
  // width: max-content;
  // margin-left: auto;
}

.encart-1 {
  margin-bottom: 30px;
}

.encart-1 a.button-primary {
  background-color: var(--color-terracotta);
}

.encart .divider,
.filtres .divider {
  margin: 20px 0;
  padding: 0px;
}

.hebergement .encart {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.hebergement .encart .form-input {
  padding-top: 5px;
  padding-bottom: 5px;
}

.hebergement .encart .select.mini .select__button {
  height: 32px;
  padding: 5px 10px;
  margin-right: 0px;
  width: 68px;
}

#resultats-contenu .divider {
  margin: 30px 0;
}

.bloc-logo {
  margin-top: -10px;
}

.baseline {
  color: white;
  padding: 5px 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}

.header-logo .baseline {
  color: white;
  padding: 4px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  font-size: 10px;
  max-width: 100px;
  display: block;
  line-height: 10px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 2px;
  text-align: center;
}

.header-logo.hero .baseline {
  color: #a4ccc5;
  padding: 8px 15px;
  font-size: 30px;
  max-width: 320px;
  display: block;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 10px;
  border-radius: 0px;
  text-align: left;
}

.accueil .heading .header-logo.hero img {
  max-width: 320px;
}

.header-logo.federation .baseline {
  padding: 5px 7px;
  font-size: 11px;
  color: #dce8e6;
  background: #3d858196;
  text-align: center;
}

.header-logo.hero.federation .baseline {
  color: #a4ccc5;
  padding: 8px 15px;
  font-size: 37px;
  max-width: 320px;
  display: block;
  line-height: 30px;
  // background: rgba(255, 255, 255, 0.1);
  background: #3d858196;
  margin-top: 10px;
  border-radius: 0px;
  text-align: center;
}

.header.is-menu-opened {
  background-color: var(--color-primary) !important;
  border-bottom: none;
}

.header.is-menu-opened .icon-menu:before {
  font-family: "uicons-solid-straight" !important;
  content: "\f514";
}

.header .header-menu .menu li a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  transition: 0.3s;
  padding: 5px 12px;
}

// .header .header-menu .menu li a:hover,
// .header .header-menu .menu li a.hover {
//   text-transform: uppercase;
//   font-size: 16px;
//   font-family: "Barlow Condensed", sans-serif;
//   font-weight: 700;
//   color: var(--color-primary-3) !important;
//   cursor: pointer;
//   background: none;
//   transition: 0.3s;
//   background-color: var(--color-primary-2) !important;
//   color: var(--color-primary) !important;
// }

.header .header-menu .menu li a:hover {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-2) !important;
  color: var(--color-primary) !important;
}

.header .header-menu .menu li.hover a,
.header .header-menu .menu li.hoveractive a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-2) !important;
  color: var(--color-primary) !important;
}

.header .header-menu .menu .subnav a.chapeau {
  background-color: var(--color-primary-4) !important;
  border-radius: 0;
  color: #d9e7e5 !important;
  // padding-left: 40px;
}

.header .header-menu .menu .subnav > li a.chapeau:hover {
  background-color: var(--color-primary-4) !important;
  border-radius: 0;
  color: #d9e7e5 !important;
  // padding-left: 40px;
}

.header .header-menu .menu .subnav li.menu-item-has-children .subnav li a.chapeau {
  background: var(--color-ocre-dark) !important;
  color: #ebe6d5 !important;
}

.header .header-menu .menu .subnav li.menu-item-has-children .subnav li a.chapeau:hover {
  background: var(--color-ocre-dark) !important;
  color: #ebe6d5 !important;
}

// .header .header-menu .menu .subnav li a {
//   border-radius: 0;
// }

// .header .header-menu .menu .subnav li.menu-item-has-children a {
//   background-color: transparent !important;
//   border-radius: 0;
// }

// .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
//   background-color: var(--color-primary-2) !important;
// }

a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-white);
}

a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.header-margin {
  position: fixed;
}

.breadcrumb {
  padding-top: 100px !important;
  margin-bottom: 0;
  background-color: var(--color-primary);
  color: var(--color-primary-2);
  font-family: "Barlow semi Condensed", sans-serif;
  font-weight: 600;
}

.breadcrumb a.link.white {
  background: linear-gradient(to right, var(--color-primary-2), var(--color-primary-2)), linear-gradient(to right, var(--color-white), var(--color-white));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-primary-2) !important;
}

.breadcrumb a.link:hover {
  background-size: 0 3px, 100% 3px;
  color: var(--color-white) !important;
}

main.accueil {
  background-color: var(--color-primary);
  overflow: hidden;
}

.accueil .footer {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-footer.svg);
  background-position: top;
  padding-top: 160px;
  color: var(--color-ocre-light);
  margin-top: -400px;
  background-color: transparent;
}

.padding-footer {
  height: 200px;
}

.accueil .heading {
  // background-image: url(../img/hero/hero-region.png);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: -300px 100px;
  background-color: var(--color-primary-4);
  padding-top: 80px;
  margin-bottom: 0;
  // padding-bottom: 100px;
}

.accueil .heading .carousel-inner {
  overflow: initial;
}

.accueil .heading .carousel-inner.full .carousel-item {
  max-height: 800px;
  overflow: hidden;
}

.accueil .heading .carousel-inner.full .carousel-item img {
  object-fit: cover;
  min-height: 80vh;
}

.accueil .heading .carousel-item {
  // max-height: 800px;
  height: 120%;
  position: relative;
}

.accueil .heading.detoure .carousel-item {
  // max-height: 800px;
  // height: 100%;
}

.accueil .heading.detoure .carousel-item.main img {
  float: left;
  margin-left: -300px;
  height: 700px !important;
  width: auto !important;
  margin-top: 100px;
  position: relative;
}

.accueil #hero-video,
.accueil .hero-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.accueil .heading .header-logo img {
  max-width: 230px;
  position: relative;
  width: 100%;
}

.accueil .suggestions {
  background-image: none;
  padding-top: 0px;
  margin-top: 0px;
}

.accueil .titre h3 {
  padding-bottom: 15px;
  color: var(--color-primary-2);
  font-size: 58px;
  margin-bottom: 0;
  line-height: 58px;
}

.accueil .titre h4 {
  font-size: 34px;
  color: var(--color-primary-3) !important;
  margin-bottom: 20px;
  line-height: 34px;
}

.accueil .educform .titre h3,
.accueil .bafa .titre h3 {
  color: var(--color-primary-1);
}

.accueil .educform .titre h4,
.accueil .bafa .titre h4 {
  color: var(--color-primary-2) !important;
}

.accueil a.button-outline {
  color: var(--color-primary-2) !important;
  border: 2px solid var(--color-primary-2) !important;
}

.accueil a.button-outline:hover {
  color: var(--color-primary-4) !important;
  background: var(--color-primary-2) !important;
}

.accueil a.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-ocre-dark);
  border-radius: 200px;
  transition: 0.3s;
  white-space: normal;
  text-align: center;
  width: fit-content;
  padding: 16px 24px;
  line-height: 20px;
}

.accueil footer a.button-primary {
  display: inline-block;
  padding: 12px 22px;
}

.accueil a.link {
  color: var(--color-primary-2);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-2), var(--color-primary-2));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
  padding-bottom: 10px;
  line-height: 35px;
}

.accueil a.link:hover {
  background-size: 0 3px, 100% 3px;
}

// background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)),
// linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));

.accueil .quotes {
  opacity: 0.15;
  position: absolute;
  width: 140px;
  top: 0px;
}

// .accueil .heading .quotes {
//   opacity: 0.15;
//   position: absolute;
//   width: 200px;
//   top: -30px;
//   left: -120px;
// }

.accueil p {
  color: var(--color-primary-2);
}

// .accueil .suggestions.sejour:after {
//   content: "";
//   clip-path: polygon(
//     100% 100%,
//     0% 100%,
//     0% 84.89%,
//     2% 85.55%,
//     4% 86.19%,
//     6% 86.8%,
//     8% 87.39%,
//     10% 87.95%,
//     12% 88.48%,
//     14% 88.99%,
//     16% 89.47%,
//     18% 89.92%,
//     20% 90.35%,
//     22% 90.75%,
//     24% 91.12%,
//     26% 91.46%,
//     28% 91.78%,
//     30% 92.06%,
//     32% 92.32%,
//     34% 92.55%,
//     36% 92.75%,
//     38% 92.92%,
//     40% 93.06%,
//     42% 93.17%,
//     44% 93.26%,
//     46% 93.31%,
//     48% 93.33%,
//     50% 93.33%,
//     52% 93.29%,
//     54% 93.23%,
//     56% 93.13%,
//     58% 93.01%,
//     60% 92.86%,
//     62% 92.67%,
//     64% 92.46%,
//     66% 92.22%,
//     68% 91.96%,
//     70% 91.66%,
//     72% 91.33%,
//     74% 90.98%,
//     76% 90.6%,
//     78% 90.19%,
//     80% 89.75%,
//     82% 89.29%,
//     84% 88.79%,
//     86% 88.28%,
//     88% 87.73%,
//     90% 87.16%,
//     92% 86.57%,
//     94% 85.95%,
//     96% 85.3%,
//     98% 84.63%,
//     100% 83.94%
//   );
// }

.accueil .enseignant {
  position: relative;
  // background-size: 100% auto;
  // background-repeat: no-repeat;
  // background-image: url(../img/backgrounds/bg-footer.svg);
  // background-position: top;
  background-color: var(--color-ocre) !important;
  color: var(--color-ocre-light);
  margin-top: 0px;
  background-color: transparent;
  padding-top: 100px;
  padding-bottom: 0px;
  color: #fbf4e7 !important;
}

.accueil .enseignant .ou {
  margin-right: 20px;
}

.accueil .enseignant .divider {
  width: 100%;
}

.accueil .enseignant p {
  color: #fbf4e7 !important;
}

.accueil .enseignant .titre {
  font-family: "Barlow condensed";
  font-size: 18px;
}

.accueil .enseignant .titre h3 {
  color: #fbf4e7 !important;
}

.accueil .enseignant .titre h4 {
  color: #ecd1a0 !important;
  margin-bottom: 0;
}

.accueil .enseignant .formules {
  color: #ecd1a0 !important;
  font-size: 26px;
  text-transform: none;
  font-weight: 500;
}

.accueil .enseignant a.button-primary {
  color: var(--color-primary-1) !important;
  background: var(--color-primary-4) !important;
  width: 100%;
  white-space: normal;
  line-height: 20px;
  padding: 16px 24px;
}

.accueil .enseignant a.button-outline {
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  font-size: 16px;
  width: 100%;
  padding: 14px 22px;
  margin-bottom: 0;
  white-space: normal;
  line-height: 20px;
}

.accueil .enseignant a.button-outline:hover {
  color: var(--color-primary-1) !important;
  background: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  font-size: 16px;
  width: 100%;
}

.accueil .enseignant .conventionnement a.button-outline {
  color: #fbf4e7 !important;
  border: 2px solid #fbf4e7 !important;
  font-size: 16px;
  width: 100%;
  padding: 6px 12px;
  white-space: normal;
  line-height: 20px;
}

.accueil .enseignant .conventionnement a.button-outline:hover {
  color: var(--color-ocre-dark) !important;
  background: #fbf4e7 !important;
  border: 2px solid #fbf4e7 !important;
  font-size: 16px;
  width: 100%;
}

.accueil .enseignant .bubble.conventionnement {
  min-width: 360px;
  margin: 0;
  // background-image: url(../img/contenus/bubble-team.svg);
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center center;
  padding: 50px;
  text-align: center;
  font-weight: 700;
  position: relative;
  min-height: 340px;
  background: var(--color-ocre-dark);
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 25s linear infinite;
  z-index: 999;
}

.accueil .enseignant .bubble.conventionnement .titre h4 {
  color: #ecd1a0 !important;
  font-size: 26px;
  line-height: 26px;
}

.accueil .enseignant .bloc-newsletter {
  max-width: 600px;
}

.accueil .enseignant .newsletter {
  width: 60px;
  height: 55px;
  border-radius: 100%;
  text-align: center;
  padding: 10px;
}

.accueil .enseignant .newsletter i:before {
  font-size: 30px;
  color: white;
}

// .accueil .enseignant .bloc-liens {
//   min-width: 240px;
// }

.accueil .enseignant .info {
  font-size: 18px;
  color: #ecd1a0 !important;
  margin-top: 20px;
  margin-bottom: 0px;
  display: inline-block;
  line-height: 20px;
  font-family: "Barlow condensed";
}

// #aux-container {
//   width: 100px;
//   height: 100px;
//   background: #f00;
//   -webkit-mask-image: radial-gradient(
//     circle 10px at 0 0,
//     transparent 0,
//     transparent 20px,
//     black 21px
//   );
// }

.curve-before {
  position: relative;
  top: 120px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 99;
}

.curve-before svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-before .shape-fill {
  fill: var(--color-primary);
}

.curve-before-invert {
  position: relative;
  top: 70px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 99;
}

.curve-before-invert svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-before-invert .shape-fill {
  fill: var(--color-primary);
}

.curve-after-invert {
  position: relative;
  bottom: 70px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 99;
}

.curve-after-invert svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-after-invert .shape-fill {
  fill: var(--color-primary);
}

.curve-after {
  position: relative;
  bottom: 120px;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 9;
}

.curve-after svg {
  position: relative;
  display: block;
  width: calc(111% + 200px);
  height: 125px;
  left: -150px;
}

.curve-after .shape-fill {
  fill: var(--color-primary);
}

.curve-equipe {
  fill: #f0d9ae;
  bottom: 70px;
}

.curve-after.curve-equipe .shape-fill {
  fill: #f0d9ae;
  bottom: 0;
}

.curve-after.curve-actualites {
  bottom: -70px;
}

.curve-after.curve-equipe-fede {
  bottom: 0px;
}

.curve-after.curve-equipe-fede .shape-fill {
  fill: #eff6f5;
}

.curve-after-invert.curve-suggestions {
  bottom: -60px;
}

.curve-after-invert.curve-recrutement {
  bottom: -5px;
}

.curve-after-invert.curve-recrutement .shape-fill {
  fill: var(--color-primary-4);
}

.curve-after.curve-actualites .shape-fill {
  fill: var(--color-primary);
}

.curve-after.curve-enseignant {
  bottom: 0px;
}

.curve-after.curve-enseignant .shape-fill {
  fill: var(--color-ocre);
}

.curve-after-invert.curve-suggestions .shape-fill {
  fill: var(--color-ocre);
}

.clip {
  background-color: red;
  clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 84.89%,
    2% 85.55%,
    4% 86.19%,
    6% 86.8%,
    8% 87.39%,
    10% 87.95%,
    12% 88.48%,
    14% 88.99%,
    16% 89.47%,
    18% 89.92%,
    20% 90.35%,
    22% 90.75%,
    24% 91.12%,
    26% 91.46%,
    28% 91.78%,
    30% 92.06%,
    32% 92.32%,
    34% 92.55%,
    36% 92.75%,
    38% 92.92%,
    40% 93.06%,
    42% 93.17%,
    44% 93.26%,
    46% 93.31%,
    48% 93.33%,
    50% 93.33%,
    52% 93.29%,
    54% 93.23%,
    56% 93.13%,
    58% 93.01%,
    60% 92.86%,
    62% 92.67%,
    64% 92.46%,
    66% 92.22%,
    68% 91.96%,
    70% 91.66%,
    72% 91.33%,
    74% 90.98%,
    76% 90.6%,
    78% 90.19%,
    80% 89.75%,
    82% 89.29%,
    84% 88.79%,
    86% 88.28%,
    88% 87.73%,
    90% 87.16%,
    92% 86.57%,
    94% 85.95%,
    96% 85.3%,
    98% 84.63%,
    100% 83.94%
  );
  width: 100%;
}

.clipp {
  background-color: #77cce9;
  clip-path: circle(80px at 50% 50%);
}

// .div1 {
//   background: green;
//   height: 300px;
// }

.div2 {
  margin-top: -100px;
  background: aqua;
  height: 300px;
  clip-path: url(#wave);
}

.footer {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-footer.svg");
  background-position: top;
  padding-top: 160px;
  color: var(--color-ocre-light);
  margin-top: -200px;
  background-color: transparent;
  font-family: "Barlow semi condensed", sans-serif;
}

footer .container.contenu {
  background-color: var(--color-ocre);
  margin-top: 100px;
}

.footer-bas {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-footer-bas.svg");
  background-position: top;
  background-color: var(--color-ocre);
  font-family: "Barlow semi condensed", sans-serif;
}

.accueil .footer-bas a.link,
.footer-bas a.link {
  margin-right: 0;
  font-size: 16px !important;
}

.footer-bas i[class*=" fi-rr-"]:before {
  font-size: 12px !important;
}

.footer li.liste-simple {
  color: var(--color-ocre-light) !important;
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.footer li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.footer p {
  color: var(--color-ocre-light) !important;
  white-space: pre-wrap;
  line-height: 24px;
}

.footer h5 {
  font-size: 22px;
  color: var(--color-ocre-light);
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer a.button-primary {
  display: inline-block;
  white-space: normal;
  line-height: 18px;
}

.footer a:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-white) !important;
}

.footer a.social img {
  width: 36px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.footer a.social img:hover {
  cursor: pointer;
  filter: invert(25%) sepia(100%) saturate(591%) hue-rotate(133deg) brightness(55%) contrast(102%);
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.p-20 {
  padding: 20px;
}

.ml-n50 {
  margin-left: -50px;
}

/******* TYPOGRAPHIES ********/

body {
  color: var(--color-text) !important;
  font-weight: 600;
  font-family: "Sen", sans-serif;
  font-size: 16px;
}

a {
  cursor: pointer;
}

.ft-primary {
  font-family: "Barlow Condensed", sans-serif;
}

.ft-secondary {
  font-family: "Jost", sans-serif;
}

.text-primary {
  color: var(--color-primary) !important;
}

p {
  color: #1b706a;
  margin: 20px 0;
  text-align: justify;
}

.from-wysiwyg,
.from-wysiwyg p,
.from-wysiwyg span,
.from-wysiwyg p span,
.from-wysiwyg strong,
.acccordion-item .from-wysiwyg {
  // color: var(--color-text);
  color: #1b706a;
  text-align: justify !important;
  font-weight: 600 !important;
  font-family: "Sen", sans-serif !important;
  font-size: 16px !important;
}

// main .informatif .from-wysiwyg p {
//   margin: 0 !important;
// }

.from-wysiwyg p {
  margin: 0 !important;
}

// main .informatif .from-wysiwyg p:first-child {
//   margin-top: 20px !important;
// }

main .informatif .from-wysiwyg > :first-child {
  margin-top: 0px !important;
}

main .informatif .from-wysiwyg p:last-child {
  margin-bottom: 30px !important;
}

.from-wysiwyg strong {
  font-weight: 800 !important;
}

.from-wysiwyg li {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.from-wysiwyg li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.from-wysiwyg li.ql-indent-1 {
  padding-left: 5rem;
}

.from-wysiwyg li.ql-indent-2 {
  padding-left: 8rem;
}

.from-wysiwyg li.ql-indent-1::before {
  margin-left: 1.5em !important;
  color: var(--color-primary-4) !important;
}

.from-wysiwyg li.ql-indent-2::before {
  margin-left: 3em !important;
  color: var(--color-primary-3) !important;
}

b,
strong {
  font-weight: 800;
}

h1 {
  color: var(--color-titre-light);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}

h2 {
  color: var(--color-sub);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
}

h3 {
  color: var(--color-ocre-dark);
  font-family: "Barlow Condensed";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  padding-bottom: 30px;
}

h4 {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
}

.heading h4 {
  font-size: 34px;
  color: var(--color-primary-3) !important;
  margin-bottom: 20px;
  line-height: 34px;
  font-weight: 700;
}

.suggestions h3 {
  padding-bottom: 15px;
}

.suggestions h4 {
  font-size: 24px;
  color: var(--color-primary-3) !important;
}

.suggestions.contact {
  position: relative;
  background-size: 130% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-wave-light.svg);
  background-position: center 0;
  padding-top: 100px;
  margin-top: 100px;
}

.suggestions.contact h2 {
  padding-bottom: 15px;
  color: var(--color-primary-4) !important;
}

.suggestions.contact h3 {
  font-size: 30px;
  color: var(--color-primary-4) !important;
  padding-bottom: 5px;
}

.suggestions.contact h4 {
  color: var(--color-primary-3) !important;
}

.suggestions .separateur i:before {
  vertical-align: middle;
  top: 5px;
  position: relative;
  margin-right: 12px;
  font-size: 35px;
}
.suggestions i.fi-rr-comments-question:before {
  vertical-align: middle;
  top: 0px;
  position: relative;
  margin-right: 5px;
  font-size: 100px;
}

.recrutement h3 {
  // color: #5e6d92;
  font-size: 30px;
  padding-top: 0;
}

h5 {
  color: var(--color-primary-4);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.numbers {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.info {
  color: var(--color-light-1);
  font-size: 13px;
  line-height: 13px;
}

.badge.info {
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.badge-duree {
  background-color: #a4bf59;
  border-radius: 0px;
  font-size: 36px;
  padding: 2px 10px;
  margin-top: -10px;
  display: inline-block;
  line-height: 44px;
  vertical-align: middle;
  margin-left: 5px;
}

.badge-duree i[class*=" fi-bs-"]:before {
  vertical-align: middle;
  top: -4px;
  position: relative;
  font-size: 26px;
}

i[class^="fi-rr-"]:before,
i[class*=" fi-rr-"]:before,
span[class^="fi-rr-"]:before,
span[class*="fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: 18px;
}

.info i[class^="fi-rr-"]:before,
.info i[class*=" fi-rr-"]:before {
  font-size: 16px;
}

i[class^="fi-ss-"]:before,
i[class*=" fi-ss-"]:before,
span[class^="fi-ss-"]:before,
span[class*="fi-ss-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 18px;
}

i[class^="fi-bs-"]:before,
i[class*=" fi-bs-"]:before,
span[class^="fi-bs-"]:before,
span[class*="fi-bs-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 18px;
}

.sejour .description ul li {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.sejour .description ul li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

li.liste-simple {
  color: var(--color-primary-4);
  list-style: none;
  position: relative;
  padding-left: 40px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 32px;
  content: "\f305";
}

.sejour .departs h5 {
  color: var(--color-primary-4);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pill {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 13px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  padding: 1px;
}

/******* ELEMENTS UI TRANSVERSES ********/

.rounded-full i[class^="fi-rr-"]:before,
.rounded-full i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.i-rounded {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.border-itinerance {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 19px;
  z-index: auto;
  border-left: 2px dashed var(--color-primary-3) !important;
}

.suivant:hover i,
.precedent:hover i {
  color: white !important;
}

.accordion__item {
  border-radius: 0px;
  border: none;
  background: none !important;
}

.accordion.-simple .accordion__item.is-active .accordion__icon {
  background-color: transparent !important;
  color: white !important;
}

/******* MENU ********/

.header-logo img {
  max-width: 100px;
}

.header .header-menu .menu li a {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  transition: 0.3s;
}

.mega-menu.recherche {
  width: 100%;
  margin-top: 160px;
  padding: 10px 40px;
  background-color: var(--color-white) !important;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  position: absolute;
  z-index: 999999;
}

.mega-menu.recherche.v2 {
  width: 100%;
  // margin-top: -165px;
  margin-top: 15px;
  padding: 10px 40px;
  background-color: var(--color-white) !important;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  position: absolute;
  z-index: 99;
}

.mega-menu.recherche .mega__grid {
  display: grid;
  gap: 40px 30px;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
}

.mega-menu.recherche.educform .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.sejours .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.bafa .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche.scolaires .mega__grid {
  grid-template-columns: auto auto auto auto;
}

.mega-menu.recherche .mega__grid.c3 {
  grid-template-columns: auto auto auto;
}

.mega-menu.recherche .mega__grid.c2 {
  grid-template-columns: auto auto;
}

.mega-menu.recherche .mega {
  top: calc(100% - 15px);
  background-color: var(--color-white) !important;
  border-radius: 0px;
  color: var(--color-primary) !important;
  min-width: 240px;
  padding: 25px 0;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mega-menu.recherche .mega a:not(.button-primary) {
  padding: 5px 15px;
  border-radius: 0px;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary) !important;
  line-height: 1.2;
  display: inline-block;
}

.mega-menu.recherche .mega a:not(.button-primary):hover {
  color: var(--color-primary-3) !important;
}

.mega-menu.recherche .mega .n1 a {
  padding: 10px 20px 10px 15px;
  border-radius: 0px;
  color: var(--color-ocre-dark) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  line-height: 1.3;
  background-color: transparent !important;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.mega-menu.recherche .mega .n1 a:hover {
  color: var(--color-white) !important;
  cursor: pointer;
  background-color: var(--color-ocre-dark) !important;
}

.mega-menu.recherche .mega .n2 a {
  padding: 8px 15px 8px 15px;
  font-size: 15px;
}

.mega-menu.recherche .mega .n2 a:hover {
  cursor: pointer;
  background-color: var(--color-primary-2) !important;
}

.mega-menu.recherche .subnav > li.n2 a {
  padding: 5px 15px 8px 15px;
  font-size: 15px;
}

.mega-menu.recherche .mega .n1 a:hover {
}

.mega-menu.recherche .mega .n3 a {
  padding: 5px 20px 5px 15px;
  font-size: 15px;
  border-radius: 0px;
  color: var(--color-primary-3) !important;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  line-height: 1.3;
  background-color: transparent !important;
  text-transform: none;
}

.mega-menu.recherche .mega .n3 a:hover {
  color: var(--color-primary) !important;
}

.mega-menu.recherche .mega a.vignette.mini {
  background-color: var(--color-bloc) !important;
  padding: 0;
  width: 100%;
}

.mega-menu.recherche .mega a.vignette.mini .vignette__content {
  padding: 0 12px;
  position: relative;
  padding-bottom: 10px !important;
}

.mega-menu.recherche .mega a.vignette.mini.educform .vignette__content {
  padding: 5px 12px 5px 12px !important;
  position: relative;
}

.mega-menu.recherche .mega a.vignette.mini.educform .vignette__image {
  height: auto;
}

.mega-menu.recherche i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: inherit;
}

.vignette.mini.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 100%;
  width: 100px;
  min-width: 100px;
}

.mega-menu.recherche h4 {
  font-size: 16px;
  line-height: 14px !important;
  margin: 5px 0 15px 0;
}

.mega-menu.recherche h5 {
  font-size: 14px;
  line-height: 13px !important;
  margin: 5px 0 8px 0;
  color: var(--color-primary-3) !important;
  font-weight: 700;
}

.mega-menu.recherche ul {
  margin-bottom: 15px;
}

.mega-menu.recherche li.liste-simple {
  color: var(--color-primary-4) !important;
  list-style: none;
  position: relative;
  padding-left: 0px;
  line-height: auto;
}

.mega-menu.recherche li.liste-simple a {
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  line-height: 14px !important;
  display: block !important;
}

.mega-menu.recherche li.liste-simple:before {
  font-family: "uicons-regular-rounded" !important;
  position: absolute;
  left: -7px;
  color: var(--color-ocre-dark) !important;
  font-size: 22px;
  content: "\f305";
}

.mega-menu.recherche li.liste-simple.theme1:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #74b7cf;
  top: 3px;
}

.mega-menu.recherche li.liste-simple.theme2:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #a4bf59;
  top: 3px;
}

.mega-menu.recherche li.liste-simple.theme3:before {
  height: calc(100% - 5px);
  width: 10px;
  position: absolute;
  left: -7px;
  content: "";
  background: #d68d64;
  top: 3px;
}

.mega-menu.recherche .vignette.mini h4 {
  font-size: 15px;
  line-height: 14px !important;
  margin: 5px 0 5px 0;
}

.vignette.mini .date {
  font-size: 12px;
  float: right;
  bottom: 0;
  position: absolute;
  left: 0;
  background: var(--color-primary-3) !important;
  width: 100%;
  color: var(--color-white) !important;
  padding: 2px 8px;
}

.vignette.mini.bafa .date {
  background: var(--color-terracotta) !important;
  width: 100%;
  color: var(--color-white) !important;
}

.vignette.mini.educform .cardImage {
  width: 100%;
  height: auto;
}

// .vignette.mini.-type-1.educform .vignette__image {
//   width: 80px;
//   min-width: 80px;
// }

.vignette.mini.educform .bandeau {
  top: 0;
  height: 100%;
  width: 20px;
  padding: 0px;
}

.vignette.mini.educform .bandeau.theme1 {
  // background: #74b7cf !important;
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.vignette.mini.educform .bandeau.theme2 {
  // background: #a4bf59 !important;
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.vignette.mini.educform .bandeau.theme3 {
  // background: #d68d64 !important;
  color: var(--color-primary-1) !important;
  background: #44bb97 !important;
}

.mega-menu.recherche .mega a.vignette.mini.educform {
  width: 100%;
}

.vignette.mini .type {
  padding: 1px 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 1px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
}

.vignette.mini.educform .type {
  display: block;
  width: fit-content;
  line-height: 12px;
}

.vignette.mini.educform .public {
  line-height: 12px;
  display: inline-block;
  margin-top: 3px;
}

.mega-menu.recherche .mega a.vignette.mini.-type-1 .vignette__image {
  height: auto;
}
.vignette.mini .type.theme1 {
  background: #74b7cf !important;
  border: 2px solid #74b7cf !important;
  color: white !important;
}

.vignette.mini .type.theme2 {
  background: #a4bf59 !important;
  border: 2px solid #a4bf59 !important;
  color: white !important;
}

.vignette.mini .type.theme3 {
  background: #d68d64 !important;
  border: 2px solid #d68d64 !important;
  color: white !important;
}

.vignette.mini.scolaire .date {
  background: #d68d64 !important;
}

.mega-menu.recherche .vignette.mini.scolaire h4 {
  font-size: 14px;
  line-height: 13px !important;
  margin: 3px 0 3px 0;
}

// .vignette.mini.scolaire .type.theme1 {
//   background: #a4bf59;
// }

// .vignette.mini.scolaire .type.theme2 {
//   background: #74b7cf;
// }

.vignette.mini .tag {
  min-width: 30px;
  height: 20px;
  background-color: var(--color-ocre-dark) !important;
  position: relative;
  padding: 0 5px;
  display: inline-block;
}

.vignette.mini .tag-title {
  line-height: 20px;
  color: #f0f0f0;
  font-size: 13px;
}

.vignette.mini i[class*=" fi-bs-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 12px;
}

.vignette.mini .tag-tail {
}

.vignette.mini .tag-tail:before,
.vignette.mini .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 10px solid var(--color-ocre-dark) !important;
}

.vignette.mini .tag-tail:after,
.vignette.mini .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-ocre-dark) !important;
}

.vignette.mini .cardImage__leftBadge {
  position: absolute;
  top: 10px;
  left: 0;
}

/******* RECHERCHE ********/

.mainSearch {
  border-radius: 100px;
}

.mainSearch__submit {
  width: 50px;
  height: 50px;
}

.mainSearch__submit:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.mainSearch h4 {
  top: 8px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.navscroll .mainSearch {
  border-radius: 0;
}

.navscroll.recherche {
  // position: sticky;
  // top: 60px;
  // z-index: 999;
  // background: #fff;
  // box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.navscroll.recherche {
  position: fixed;
  width: 100%;
  top: 60px;
  z-index: 999;
  background: #fff;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.mainSearch .select__search {
  border-radius: 0;
}

.mainSearch input.select__search {
  padding-left: 20px;
  margin: 10px 10px 0 10px;
  width: calc(100% - 20px);
}

.mainSearch .select button {
  border-radius: 0px;
  border: none;
  background: none;
  padding-bottom: 0;
}

.mainSearch .select__button span {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
}

.mainSearch .select__button {
  padding-left: 0;
  height: auto;
  padding-top: 0;
}

.mainSearch .libelle {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
}

.mainSearch .select__options__button {
  padding: 5px;
  border-radius: 0px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary-4) !important;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 20px;
}

.mainSearch .counter {
  min-height: 30px;
}

.form-checkbox__mark {
  border: 2px solid var(--color-primary-4);
}

.form-checkbox input:checked ~ .form-checkbox__mark {
  background-color: var(--color-primary-4);
  border-color: var(--color-primary-4);
}

#sejour.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 150px 200px 200px auto;
}

#bafa.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 250px 250px auto;
}

#educform.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 300px 200px auto;
}

// #educform.mainSearch .button-grid {
//   display: grid;
//   grid-template-columns: 1fr 200px auto;
// }

#thematique.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 200px 300px auto;
}

#sejour.mainSearch {
  max-width: 1000px;
}

#multi.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

#actualites.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

#resultats-filtres a .info {
  border: 2px solid #c7d4d5;
  padding: 12px 20px 7px 20px;
  border-radius: 100px;
  color: #9bb2b4;
}

#resultats-filtres a .info:hover {
  border: 2px solid #c7d4d5;
  background: #c7d4d5;
  color: #73898b;
}

.content.multi #resultats-filtres {
  transition: 0.3s ease;
  position: relative;
  margin-top: 0px;
}

.content.unitaire #resultats-filtres {
  transition: 0.3s ease;
  position: relative;
  margin-top: 0px;
  // flex-grow: 1;
}

.content.unitaire .toggle .button.masquer {
  margin-top: -43px;
}

.content.multi .toggle .button.masquer {
  margin-top: -43px;
}

.publications .content.unitaire .toggle .button.masquer {
  margin-top: 20px;
}

.resultats.actualites.publications .vignette {
  background-color: #f3f4f4;
  padding-bottom: 0;
}

.resultats.actualites.publications .vignette .vignette__content {
  padding: 0px 20px 20px 20px;
}

.resultats.actualites.publications .tarif {
  font-size: 18px;
}

#resultats-contenu {
  transition: 0.3s ease;
  // flex-shrink: 1;
}

.w-0 {
  width: 0 !important;
}

/******* BOUTONS ********/

.button.masquer {
  z-index: 9;
  border-radius: 0;
  padding: 10px 15px;
  text-align: left;
  font-family: "Barlow Condensed";
  position: relative;
  position: absolute;
  font-weight: 600;
  top: 20px;
  right: 20px;
}

// .content .toggle {
//   margin-top: -43px;
// }

.recherche .container {
  position: relative;
}

.button.masquer:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.toggle .button.masquer {
  position: relative;
  z-index: 99;
  border-radius: 0;
  padding: 10px 15px;
  top: 0;
  width: 100%;
  right: 0;
  justify-content: start;
  font-family: "Barlow Condensed";
  font-weight: 600;
  margin-top: -110px;
}

#toggle-carte.button.masquer {
  position: relative;
  top: 0;
  right: 0;
}

// a.link {
//     background:
//        linear-gradient(
//          to bottom,
//          var(--color-ocre) 0%,
//          var(--color-ocre) 100%
//        );
//     background-position: 0 100%;
//     background-repeat: repeat-x;
//     background-size: 2px 3px;
//     color: var(--color-white);
//     text-decoration: none;
//     transition: background-size .2s;
// }

// a.link:hover {
//     background-size: 3px 7px;
// }

a.link {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline !important;
  font-size: 16px;
  margin-right: 15px;
  padding-bottom: 7px;
  line-height: 35px;
}

a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  color: var(--color-white);
}

a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.from-wysiwyg a {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline !important;
  font-size: 16px;
  margin-right: 0px;
  padding-bottom: 7px;
  line-height: 35px;
}

.from-wysiwyg a:hover {
  background-size: 0 3px, 100% 3px;
}

// .tabs a.link:hover {
//   color: var(--color-primary-4);
// }

.tabs a.link {
  height: 30px;
  padding: 0;
  margin: 15px;
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-primary-3);
}

.tabs a.link.all {
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-primary-3);
}

a.link.all:hover {
  background-size: 0 3px, 100% 3px;
}

.tabs a.link.active {
  background: linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-ocre-dark) !important;
  cursor: default;
}

// .tabs a.link.is-tab-el-active {
//   background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)),
//     linear-gradient(to right, var(--color-ocre), var(--color-ocre));
//   background-size: 100% 3px, 0 3px;
//   background-position: 100% 100%, 0 100%;
//   background-repeat: no-repeat;
//   transition: background-size 400ms;
//   height: 30px;
//   display: inline-block !important;
//   color: var(--color-ocre) !important;
//   cursor: default;
// }

.accueil .tabs a.link {
  color: var(--color-primary-2);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-2), var(--color-primary-2)), linear-gradient(to right, var(--color-ocre), var(--color-ocre));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  font-size: 17px;
  font-family: "Barlow Condensed";
  margin-right: 10px;
  font-weight: 600;
}

.accueil .tabs a.link.is-tab-el-active {
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre), var(--color-ocre));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 30px;
  display: inline-block !important;
  color: var(--color-ocre) !important;
  cursor: default;
}

.accueil .tabs a.link:hover {
  color: var(--color-ocre);
  background-size: 0 3px, 100% 3px;
}

.accueil .enseignant a.link.white {
  background: linear-gradient(to right, var(--color-white), var(--color-white)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 35px;
  display: inline !important;
  font-size: 18px;
  font-family: "Barlow semi Condensed";
  margin-right: 15px;
  padding-bottom: 10px;
  line-height: 35px;
  color: var(--color-white);
}

.accueil .enseignant a.link.white:hover {
  background-size: 0 3px, 100% 3px;
}

.accueil .mainSearch h4 {
  margin-bottom: 0;
  color: var(--color-primary-4) !important;
}

a.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-primary:hover {
  // background-color: var(--color-primary-1) !important;
  // box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.button-primary {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

.button-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

a.button-primary.secteur {
  border-radius: 0;
  max-width: 300px;
  position: fixed;
  top: 10px;
  z-index: 999;
  line-height: 18px;
  text-align: left;
  white-space: normal;
  background-color: var(--color-ocre-dark);
}

a.button-primary.secteur.sub {
  border-radius: 0;
  max-width: 300px;
  position: fixed;
  top: 10px;
  z-index: 999;
  line-height: 18px;
  text-align: left;
  white-space: normal;
  font-size: 14px;
}

.encart a.button-primary.demande,
.encart .button-primary.demande {
  color: var(--color-white) !important;
  background-color: var(--color-ocre-dark);
}

.encart a.button-primary.devis,
.encart .button-primary.devis {
  color: var(--color-ocre-dark) !important;
  background-color: transparent;
  border: 2px solid var(--color-ocre-dark) !important;
  padding: 8px 18px;
}

.encart a.button-primary.devis:hover,
.encart .button-primary.devis:hover {
  background-color: var(--color-ocre-dark);
  border: 2px solid var(--color-ocre-dark) !important;
  color: var(--color-white) !important;
  box-shadow: none;
}

.encart a.button-primary.demandeprojet,
.encart .button-primary.demandeprojet {
  color: var(--color-primary-4) !important;
  background-color: transparent;
  border: 2px solid var(--color-primary-4) !important;
  padding: 8px 10px;
  font-size: 15px;
}

.encart a.button-primary.demandeprojet:hover,
.encart .button-primary.demandeprojet:hover {
  background-color: var(--color-primary-4);
  border: 2px solid var(--color-primary-4) !important;
  color: var(--color-white) !important;
  box-shadow: none;
}

.button-secondary {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-secondary) !important;
  padding: 10px 15px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-secondary {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  background-color: var(--color-secondary) !important;
  padding: 10px 15px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
}

a.button-secondary:hover {
  background-color: var(--color-primary-2) !important;
  color: var(--color-secondary) !important;
}

.button-secondary:hover {
  background-color: var(--color-primary-2) !important;
  color: var(--color-secondary) !important;
}

a.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
}

a.button-outline.share {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 5px 15px 5px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
  z-index: 999;
  max-width: 120px;
}

.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 10px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 8px;
}

a.button-outline:hover {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white) !important;
}

.button-outline:hover {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white) !important;
}

/******* GALERIE ********/

.galleryGrid.gallery4 {
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 0.52fr 0.24fr 0.24fr;
  gap: 10px;
}

.galleryGrid.gallery4 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.gallery4 > *:nth-child(2) {
  grid-column: 2 span;
}

.galleryGrid.gallery3 {
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 0.52fr 0.52fr;
  gap: 10px;
}

.galleryGrid.gallery3 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.gallery3 > *:nth-child(2) {
  grid-column: 1 span;
}

.galleryGrid.gallery2 {
  display: grid;
  grid-template-rows: 500px;
  grid-template-columns: 0.52fr 0.52fr;
  gap: 10px;
}

.galleryGrid.gallery2 > *:nth-child(1) {
  grid-row: 1 span;
}

.galleryGrid.gallery2 > *:nth-child(2) {
  grid-column: 1 span;
}

.galleryGrid.gallery1 {
  display: grid;
  grid-template-rows: 500px;
  grid-template-columns: 1fr;
  gap: 10px;
}

.galleryGrid.gallery1 > *:nth-child(1) {
  grid-row: 1 span;
}

.galleryGrid.grid-educform,
.galleryGrid.grid-scolaireactivite {
  grid-template-rows: 350px;
  grid-template-columns: 1fr;
}

.galleryGrid.grid-educform > *:nth-child(1),
.galleryGrid.grid-scolaireactivite > *:nth-child(1) {
  grid-row: auto;
}

.galleryGrid.grid-actualite {
  grid-template-rows: 350px;
  grid-template-columns: 1fr;
}

.galleryGrid.grid-actualite > *:nth-child(1) {
  grid-row: auto;
}

.galleryGrid.grid-actualite .bandeau-actu {
  width: 100%;
  height: 40px;
  background: var(--color-primary-3);
  position: absolute;
  bottom: 0;
}

.galleryGrid.grid-educform .bandeau-theme1,
.galleryGrid.grid-educform .bandeau-theme2,
.galleryGrid.grid-scolaireactivite .bandeau-theme1,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 {
  width: 100%;
  height: 40px;
  background: #74b7cf;
  position: absolute;
  bottom: 0;
}

.galleryGrid.grid-educform .bandeau-theme2,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 {
  background: #a4bf59;
}

.galleryGrid.grid-educform .bandeau-theme3,
.galleryGrid.grid-scolaireactivite .bandeau-theme3 {
  background: #d68d64;
}

.galleryGrid.grid-educform .bandeau-theme1 span,
.galleryGrid.grid-educform .bandeau-theme2 span,
.galleryGrid.grid-scolaireactivite .bandeau-theme1 span,
.galleryGrid.grid-scolaireactivite .bandeau-theme2 span,
.galleryGrid.grid-actualite .bandeau-actu span {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
  text-transform: uppercase;
  padding: 5px 20px;
  display: inline-block;
}

h1.scolairethematique i:before {
  font-size: 46px;
  margin-top: -5px;
  display: inline-block;
}

main.actualite h2 {
  color: var(--color-sub);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-transform: none;
}

main.actualite h2 i:before {
  color: var(--color-sub);
  font-size: 28px;
}

main.actualite a.link {
  margin-right: 10px;
}

main.actualite .description ul li.liste-simple {
  color: var(--color-text) !important;
}

main.actualite .item-actu {
  margin-bottom: 0px;
}

main.actualite .item-actu a {
  line-height: 15px;
  display: inline-block;
}

main.actualite .item-actu .info {
  margin-left: 0px;
  line-height: 10px;
}

main.actualite .item-actu-img {
  width: 60px;
  height: 60px;
}
main.actualite .item-actu-img img {
  object-fit: cover;
  width: 60px;
  height: 60px;
}

.checkbox-wrapper.actus label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
}

/******* PAGES INFORMATIVES *******/

// .paddingBlock {
//   padding: 20px 0;
// }

// .eqWrap {
//   display: flex;
// }

// .eq {
//   color: #fff;
//   padding: 10px;
// }

// .eq:nth-of-type(odd) {
//   background: #706aa1;
// }

// .eq:nth-of-type(even) {
//   background: #46bac9;
// }

// .equalHW {
//   flex: 1;
// }

// .equalHMWrap {
//   justify-content: space-between;
// }

// .equalHM {
//   width: 32%;
// }

// .equalHMRWrap {
//   justify-content: space-between;
//   flex-wrap: wrap;
// }
// .paddingBlock {
//   padding: 20px 0;
// }

// .eqWrap {
//   display: flex;
// }

// .eq {
//   color: #fff;
//   padding: 10px;
// }

// .eq:nth-of-type(odd) {
//   background: #706aa1;
// }

// .eq:nth-of-type(even) {
//   background: #46bac9;
// }

// .equalHW {
//   flex: 1;
// }

// .equalHMWrap {
//   justify-content: space-between;
// }

// .equalHM {
//   width: 32%;
// }

// .equalHMRWrap {
//   justify-content: space-between;
//   flex-wrap: wrap;
// }

// .equalHMR {
//   width: 32%;
//   margin-bottom: 2%;
// }

// .equalHMVWrap {
//   flex-wrap: wrap;
// }

// .equalHMV {
//   width: 32%;
//   margin: 1%;
// }

// .equalHMV:nth-of-type(3n) {
//   margin-right: 0;
// }

// .equalHMV:nth-of-type(3n + 1) {
//   margin-left: 0;
// }

// .equalHMR {
//   width: 32%;
//   margin-bottom: 2%;
// }

// .equalHMVWrap {
//   flex-wrap: wrap;
// }

// .equalHMV {
//   width: 32%;
//   margin: 1%;
// }

// .equalHMV:nth-of-type(3n) {
//   margin-right: 0;
// }

// .equalHMV:nth-of-type(3n + 1) {
//   margin-left: 0;
// }

main .informatif p {
  margin: 15px 0 20px 0 !important;
}

main .informatif .swiper-slide p {
  margin: 0 !important;
}

main .informatif ul,
main .informatif ol {
  margin: 5px 0 20px 0 !important;
}

main .informatif section {
  padding: 40px 0 !important;
}

main .informatif section.telechargements {
  padding: 20px 0 !important;
}

main .informatif section.texte {
  padding: 40px 0 !important;
  padding-top: 20px !important;
}

main .informatif section.image img {
  width: 100%;
}

main .informatif section.equipe {
  padding: 0 !important;
}

main .informatif section.equipe .bubble.team {
  margin: 0 auto;
}

main .informatif section.equipe .bubble.team.photo {
  margin: 0 auto;
  position: absolute;
  background-color: transparent !important;
}

main .informatif section.equipe .child:hover .bubble.team {
  cursor: pointer;
}

main .informatif section.equipe .child:hover .bubble.team,
main .informatif section.equipe .child.active .bubble.team {
  // background: var(--color-primary-4);
  // background-color: #015f59 !important;
  // opacity: 0.85;
}

main .informatif section.equipe .child:hover .bubble.team.photo {
  background-color: transparent !important;
  opacity: 1;
  cursor: default;
}

main .informatif section.equipe .child.tous.active .bubble.team {
  background-color: #015f59 !important;
}

main .informatif section.equipe .child.active .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

main .informatif section.equipe .child.withhover:hover .bubble.team.photo {
  cursor: pointer;
  background-color: #015f59 !important;
  opacity: 0.3;
}

main .informatif section.equipe .child.withhover:hover .bubble.team,
main .informatif section.equipe .child.withhover.active .bubble.team {
  // background: var(--color-primary-4);
  background-color: #015f59 !important;
  opacity: 0.85;
}

main .informatif section.equipe .child:hover .nom,
main .informatif section.equipe .child:hover .info {
  // color: var(--color-primary-4);
  // cursor: pointer;
  color: var(--color-primary-3);
  cursor: default;
}

main .informatif section.equipe .child.withhover:hover .nom,
main .informatif section.equipe .child.withhover:hover .info {
  color: var(--color-primary-4);
  cursor: pointer;
  transition: 0.3s;
}

main .informatif section.equipe .child.active .nom:hover,
main .informatif section.equipe .child.active .info:hover,
main .informatif section.equipe .child.active .bubble.team:hover {
  color: var(--color-primary-4);
  cursor: default;
}

main .informatif section.equipe .child .nom {
  line-height: 24px;
  margin-top: 16px;
  transition: 0.3s;
}

main .informatif section.equipe .child.tous.active .nom {
  color: var(--color-primary-4);
  line-height: 24px;
  margin-top: 16px;
}

section.aroevens.equipe.team .swiper-slide a[href="#"] {
  cursor: default !important;
}

section.aroevens.equipe.team .bubble.team {
  margin: 0 auto;
  transition: 0.3s;
}

section.aroevens.equipe.team .bubble.team.photo {
  margin: 0 auto;
  position: absolute;
  background-color: transparent !important;
}

section.aroevens.equipe.team .child.active .bubble.team {
  // background: var(--color-primary-4);
  background-color: #015f59 !important;
  opacity: 0.85;
}

section.aroevens.equipe.team .child.withhover:hover .bubble.team {
  // background: var(--color-primary-4);
  background-color: #015f59 !important;
  opacity: 0.85;
}

section.aroevens.equipe.team .child.active .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

// section.aroevens.equipe.team .child.withhover:hover .bubble.team.photo {
//   background-color: transparent !important;
//   opacity: 1;
// }

section.aroevens.equipe.team .child.withhover:hover .bubble.team.photo {
  background-color: #015f59 !important;
  opacity: 0.3;
}

section.aroevens.equipe.team .child:hover .nom,
section.aroevens.equipe.team .child:hover .info {
  color: var(--color-ocre);
  cursor: default;
}

section.aroevens.equipe.team .child.active .nom:hover,
section.aroevens.equipe.team .child.active .info:hover,
section.aroevens.equipe.team .child.active .bubble.team:hover {
  color: var(--color-primary-4);
  cursor: default;
}

section.aroevens.equipe.team .child.withhover:hover .nom,
section.aroevens.equipe.team .child.withhover:hover .info {
  color: var(--color-primary-4);
  cursor: pointer;
  transition: 0.3s;
}

section.aroevens.equipe.team .child .nom {
  line-height: 24px;
  margin-top: 16px;
}

section.aroevens.equipe.team .child.tous.active .nom {
  color: var(--color-primary-4);
  line-height: 24px;
  margin-top: 16px;
}

main .informatif section.equipe .child:hover .bubble.team {
  cursor: default;
}

main .informatif section.equipe .child.tous:hover .bubble.team {
  cursor: pointer;
}

main .informatif section.equipe .child.withhover:hover .bubble.team {
  cursor: pointer;
}

main .informatif > section:last-of-type {
  padding-bottom: 100px !important;
}

.informatif .divider {
  margin: 30px 0;
}

// border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
// border-radius: 40% 30% 40% 30% / 50% 50% 60% 70%;

// .informatif .blob {
//   background: var(--color-primary-4);
//   width: auto;
//   height: 85%;
//   border-radius: 50% 60% 70% 40% / 50% 50% 60% 50%;
//   overflow: hidden;
//   animation: none;
//   z-index: 999;
//   top: 40px;
//   aspect-ratio: 9 / 8;
// }

.informatif .blob {
  background: var(--color-primary-4);
  border-radius: 50% 60% 70% 40% / 50% 50% 60% 50%;
  overflow: hidden;
  animation: none;
  z-index: 999;
  top: 40px;
  aspect-ratio: 9 / 8;
  max-width: 500px;
  width: auto;
  height: auto;
  min-width: 420px;
}

section.content.informatif {
  z-index: 9;
  position: relative;
}

.informatif .parent {
  display: flex;
  gap: 40px;
}

.informatif .child {
  // flex-basis: 100%;
}

.informatif .telechargements .child {
  flex: 1 0 41%;
}

// .informatif .child .bloc-img {
//   width: 600px;
//   height: 600px;
// }

.informatif .child img,
.informatif .child video {
  object-fit: cover;
  width: 100%;
}

.informatif .child .social img {
  object-fit: cover;
  width: auto;
}

.informatif .texte-galerie .container {
  // padding-right: 15px;
  // padding-left: 15px;
}

.informatif .texte-galerie .blob img,
.informatif .texte-galerie .blob video {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
  transform: scale(1.1);
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.informatif .texte-galerie .blob img:hover,
.informatif .texte-galerie .blob video:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.informatif .texte-galerie .parent.carre .blob {
  max-width: 300px;
  background: none;
  border-radius: 0;
}

.informatif .texte-galerie .parent.carre .blob img,
.informatif .texte-galerie .parent.carre .blob video {
  object-fit: contain;
  width: 100%;
  aspect-ratio: auto;
  transform: none;
}

.informatif .texte-galerie .blob img a,
.informatif .texte-galerie .blob video a {
  height: auto;
  display: block;
  overflow: hidden;
  margin: auto;
}

main .informatif section.equipe .team img {
  width: 100px;
  bottom: -15px;
  position: relative;
}

main .informatif section.equipe .team.photo img {
  bottom: auto;
  top: -10px;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  min-width: 150px;
}

main .informatif section.equipe .child.tous .team img {
  width: 100px;
  bottom: initial;
  position: relative;
}

main .informatif section.equipe .child {
  // margin-bottom: 30px;
}

.informatif .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 20px;
  margin: 0;
  position: relative;
  height: 100%;
}

.informatif .texte table.table-4 th:first-child,
.informatif .texte table.table-4 th:last-child {
  border-radius: 0;
}

.informatif .texte-galerie .parent:has(.child) {
  gap: 40px;
  justify-content: space-evenly;
}

.informatif.contact .texte-galerie .parent:has(.child) {
  gap: 20px;
  justify-content: start;
}

.informatif .texte-galerie .parent:has(.child.tiny) {
  gap: 40px;
  justify-content: space-evenly;
}

.informatif .texte-galerie .from-wysiwyg {
  margin-top: 50px;
}

// .informatif .texte-galerie .child {
//   flex-basis: 100%;
//   flex: 0 0 215px;
// }

.informatif .texte-galerie .child {
  flex-basis: 100%;
  flex: 1;
  min-width: 185px;
  max-width: 400px;
}

.informatif.contact .texte-galerie .parent .child {
  max-width: 180px;
  min-width: 180px;
  margin-bottom: 0;
}

.informatif .texte-galerie .child.tiny {
  flex: 0 0 185px;
}

.informatif .texte-galerie .wrap {
  height: auto;
  aspect-ratio: 1 / 1;
}

.informatif .texte-galerie .blob {
  height: 100%;
  width: 100%;
  min-width: initial;
}

.informatif h2 {
  color: var(--color-primary-4) !important;
  line-height: 48px;
  margin-bottom: 40px;
}

.informatif h3 {
  color: var(--color-ocre-dark) !important;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.informatif h4 {
  color: var(--color-primary-3) !important;
  padding: 5px 0;
  font-size: 30px;
  line-height: 28px;
  text-align: left;
  margin: 15px 0;
}

.informatif .from-wysiwyg h5 {
  background: #81b1ac !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  display: inline-block;
  padding: 5px 15px;
  // margin: 10px 0 5px 0;
  margin: 20px 0 15px 0;
  text-align: left;
}

.informatif .from-wysiwyg br {
  content: "";
  margin: 4em;
  display: block;
  font-size: 24%;
}

.informatif .link-externe h5 {
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
}

.informatif .footer h5 {
  color: var(--color-ocre-light) !important;
}

.informatif .telechargements h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow condensed", sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.telechargements h5 a.link {
  text-transform: none !important;
}

.informatif .telechargements .parent {
  gap: 20px;
}

.informatif a.button-primary {
  padding: 10px 40px;
  margin-right: 10px;
}

.informatif a.button-outline {
  padding: 10px 40px;
  margin-right: 10px;
}

.informatif .texte-punchline h2 {
  color: var(--color-primary-4) !important;
  line-height: 40px;
}

.informatif .accordion-button {
  padding-left: 0;
  line-height: 25px;
}

.informatif .accordion-button:not(.collapsed) {
  color: var(--color-primary-4) !important;
  background-color: transparent;
  box-shadow: none;
}

.informatif .accordion-body {
  padding: 1rem 1.25rem;
  padding-left: 0;
  padding-top: 0;
}

.informatif .texte-carousel a.button-outline {
  padding: 10px 20px;
}

.bloc-login .container {
  max-width: 560px;
}

.login {
  margin-top: -200px;
  padding-top: 200px;
}

.enseignant .login {
  margin-top: -200px;
  padding-top: 150px;
}

.login .form-input label {
  top: 32px;
}

.enseignant .heading .curve {
  z-index: 99;
}

.enseignant-image {
  // background-image: url("https://res.cloudinary.com/mhmd/image/upload/v1555917661/art-colorful-contemporary-2047905_dxtao7.jpg");
  background-size: cover;
  background-position: center center;
  min-height: calc(100vh - 100px);
}

.confirmation-image {
  background-size: cover;
  background-position: center center;
  min-height: 1150px;
}

.enseignant .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px 0px 10px;
  font-size: 22px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.login .footer {
  margin-top: -400px;
}

a.link-enseignant {
  margin-right: 10px;
  text-transform: none;
  color: var(--color-primary-4) !important;
  font-size: 17px;
  margin: 5px 0;
  padding: 15px 20px 15px 30px !important;
  background: #dfe8e6 !important;
  height: auto;
  display: inline-block;
  width: 100%;
}

a.link-enseignant h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
  font-weight: 700;
}

a.link-enseignant:hover h5 {
  background-size: 0 3px, 100% 3px;
}

a.link-enseignant:hover {
  background: #d1dfdd !important;
}

a.link-enseignant i:before,
.enseignant .steps i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  margin-top: -4px;
  position: relative;
  font-size: 26px !important;
  display: inline-block;
}

a.link.voirtout {
  display: inline-block !important;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.container.sous-pages {
  padding-top: 50px;
}

.container.sous-pages .colonnes-2 {
  -webkit-column-count: 2; /* Chrome/Opera, Safari */
  -moz-column-count: 2; /* Mozilla Firefox */
  column-count: 2;
}

.sous-pages a.link,
.sous-pages h5 {
  margin-right: 10px;
  text-transform: none;
  color: var(--color-primary-4) !important;
  font-size: 17px;
}

.sous-pages .link-externe {
  color: var(--color-primary-4) !important;
  margin: 0 0 10px 0;
  padding: 15px 20px 15px 30px !important;
}

.sous-pages .bg-bloc.n1 {
  color: var(--color-ocre-dark) !important;
  background: #f6f2e8 !important;
}

.sous-pages .bg-bloc:hover {
  background: #e5eeed !important;
}

.sous-pages .bg-bloc.n1:hover {
  background: #f8eed3 !important;
}

.sous-pages .bg-bloc.n1 a.link {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc.n1 a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.sous-pages .bg-bloc h5 {
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc.n1 h5 {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, var(--color-ocre), var(--color-ocre)), linear-gradient(to right, var(--color-ocre-dark), var(--color-ocre-dark));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  min-height: 30px;
  display: inline !important;
  line-height: 30px;
  padding-bottom: 7px;
}

.sous-pages .bg-bloc:hover h5 {
  background-size: 0 3px, 100% 3px;
}

.sous-pages ul {
  margin-left: 40px;
  margin-bottom: 20px;
}

.sous-pages .liste-simple {
  color: var(--color-primary-3) !important;
  text-decoration: none;
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: auto;
  display: block !important;
  line-height: 30px;
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;
}

.sous-pages li.liste-simple:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: var(--color-primary-3) !important;
  font-size: 32px;
  content: "\f305";
  top: 5px;
}

.sous-pages .liste-simple a.link {
  color: var(--color-primary-3) !important;
  font-size: 15px;
}

.img-actu-presse {
  margin: 30px 0;
  position: relative;
}

.img-actu-presse .source {
  padding: 12px 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  color: var(--color-primary-2) !important;
  background: rgba(27, 112, 106, 0.6) !important;
  font-size: 14px;
  line-height: 16px;
}

.img-actu-presse img {
  // object-fit: cover;
  // width: 100%;
  // aspect-ratio: 1/1;
  // margin: 30px 0;
  // transition: 1s;
  object-fit: cover;
  width: 100%;
  // aspect-ratio: 1/1;
  // transform: scale(1.1);
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.img-actu-presse img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.img-actu-presse a {
  height: auto;
  display: block;
  overflow: hidden;
  margin: auto;
}

.informatif .texte-colonne .parent .child {
  min-width: 300px;
  flex: 1;
}

.informatif .texte-image .parent {
  align-items: center;
  gap: 80px;
}

/******* FORMULAIRES ********/

.formulaire {
  background: var(--color-bloc);
  padding: 40px;
}

.form-control:focus {
  color: var(--color-primary-4);
  background-color: inherit;
  border-color: var(--color-primary-4);
  outline: 0;
  box-shadow: none;
}

.form-input input,
.form-input textarea {
  background: #e3e8e7;
  border-radius: 0;
  border: none;
  color: var(--color-primary-4);
  font-weight: 700;
  min-height: 60px;
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #e3e8e7 !important;
}

.form-input textarea label {
  top: 0;
}

.form-input label {
  top: 30px;
}

.form-input label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  color: #93abad;
  font-size: 18px;
  line-height: 16px;
}

.formulaire .form-input label,
.informatif .form-input label {
  top: 22px;
}

.formulaire .obligatoire {
  color: var(--color-terracotta) !important;
}

.form-input.date label {
  top: 20px;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
  color: var(--color-primary-4);
  padding-top: 14px;
  padding-left: 20px;
  font-size: 16px;
}

input[type="file"]::file-selector-button {
  font-weight: bold;
  color: var(--color-primary-4);
  padding: 0.5em;
  border: none;
  font-size: 16px;
  background: var(--color-bloc);
  background-color: var(--color-bloc);
  border-radius: 0;
}

input[type="file"]::file-selector-button:hover {
  background: var(--color-bloc);
  background-color: var(--color-bloc);
}

// input[type="file"] {
//   display: none;
// }

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

input[type="text"]:disabled {
  background: #edf1f0;
}

input[type="text"]:disabled label {
  color: #bfccd1 !important;
}

.form-input:has(> input[type="text"]:disabled) label {
  color: #bfccd1 !important;
}

.form-input input[type="text"]:disabled {
  border-color: #edf1f0 !important;
}

.processus-reservation .steps .select.small button:disabled {
  background: #edf1f0;
}

.processus-reservation .steps .select.small button:disabled span,
.processus-reservation .steps .select.small button:disabled i {
  color: #bfccd1 !important;
}

.processus-reservation .mainSearch__submit:hover {
  box-shadow: none;
}

.form-input textarea:focus,
.form-input input:focus {
  border: 2px solid var(--color-primary-4) !important;
}

.processus-reservation label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
}

img.logos-cb {
  max-width: 300px;
  height: auto;
  margin: 10px 0 20px 0px;
}

.montant a .condition {
  background: #d9e2e1 !important;
  min-width: 200px;
}

.montant a .condition.active {
  background: var(--color-primary-3) !important;
  color: #d9e2e1 !important;
}

.montant a .condition.active h5,
.montant a .condition.active .text-primary {
  color: #fff !important;
}

.recrutement label {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 8px !important;
}

.form-switch {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
  color: var(--color-primary-4) !important;
  font-size: 18px;
}

.recherche input {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 0;
}

.mainSearch input {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 0;
}

.recherche input::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #bbb;
  font-size: 16px;
  line-height: 16px;
}

.accueil input::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #bbb;
  font-size: 16px;
  line-height: 16px;
}

.form-input textarea::placeholder {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 600;
  color: #93abad !important;
  font-size: 18px;
  line-height: 16px;
  padding-top: 5px;
}

.form-input textarea {
  padding-top: 25px;
}

.accueil .enseignant .mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.accueil .enseignant input:not([type="range"]),
textarea {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
  padding-left: 0;
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 0px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background-color: white;
  // padding: 20px;
  width: 100%;
  min-height: 50px;
  max-height: 300px;
  box-shadow: none;
  border: none;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
}

.select-sub {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary-3);
  text-align: end;
  line-height: 18px;
}

.select__dropdown {
  padding: 0;
}

.select__options {
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

.mainSearch .select__options {
  padding: 10px 0 0 0;
}

.recherche .select__dropdown {
  width: calc(100% + 40px);
}

.js-rayon-rangeSlider {
  padding: 0 20px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.slider-geo {
  padding: 10px 20px 20px 20px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  line-height: 16px;
}

.select__options__button {
  padding: 5px;
  border-radius: 0px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: transparent !important;
  color: var(--color-primary-4) !important;
  font-size: 17px;
  line-height: 15px;
}

.select__options__button {
  line-height: 20px;
  padding: 15px 20px;
  font-size: 17px;
  line-height: 15px;
}

.select__options__button:hover {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: none;
  transition: 0.3s;
  background-color: var(--color-primary-1) !important;
  color: var(--color-primary-4) !important;
}

.select.libelle label {
  display: inline-block;
  position: absolute;
  top: 8px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.sidebar__item:not(.-no-border) {
  border: none;
}

.select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  padding-top: 20px;
  line-height: 15px;
}

.mainSearch .select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0px;
  line-height: 15px;
}

.select:not(.libelle) .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0px;
  line-height: 15px;
}

.select button {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 5px 15px 5px 15px;
}

.tri {
  padding-left: 5px;
  padding-right: 5px;
}
.select.mini .select__button {
  height: 42px;
}

.select.mini .select__dropdown {
  padding: 0;
}

.select.mini .select__options {
  // width: calc(100% + 4px);
  width: 100%;
}

.select.mini .select__options__button {
  font-size: 15px;
  padding: 5px 15px;
  line-height: 30px;
}

.select.mini .select__options__button:hover {
  background-color: var(--color-primary-1) !important;
}

.select.mini .select__button span {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-top: 0;
  margin-right: 15px;
}

.filtres .sidebar {
  padding: 25px;
  position: relative;
}

.filtres .sidebar .button.masquer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.sidebar.filtres a.link {
  display: inline-block !important;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.filtres .select button {
  padding: 12px 18px;
  height: auto !important;
}

.checkbox-wrapper .checkbox {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.processus-reservation.publications .checkbox-wrapper label span {
  float: left;
  margin-top: 3px;
}

.processus-reservation.publications .checkbox-wrapper {
  // margin-bottom: 10px;
}

.processus-reservation.publications .accordion-flush .accordion-item {
  background: none;
}

.processus-reservation.publications .accordion-flush .accordion-item .accordion-body {
  padding: 0;
}

.processus-reservation.publications .accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  text-transform: none;
  font-size: 20px;
  background: none;
  padding: 10px 0px;
}

.processus-reservation.publications .select.mini .select__button {
  height: 32px;
  padding: 5px 10px;
  margin-right: 5px;
  width: 68px;
}

.processus-reservation.publications .checkbox-wrapper label p {
  vertical-align: middle;
  color: var(--color-primary-4);
  margin-top: -5px;
  display: inline;
}

.checkbox-wrapper label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 16px;
}

.checkbox-wrapper label.important {
  color: var(--color-ocre-dark);
  font-size: 16px;
}

.checkbox-wrapper label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-primary-4);
  border-radius: 0;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}
.checkbox-wrapper label span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 5px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}
.checkbox-wrapper label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(305deg);
  top: 12px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
.checkbox-wrapper label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}
.checkbox-wrapper label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

.checkbox-wrapper label.important span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-ocre-dark);
  border-radius: 0;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}
.checkbox-wrapper label.important span:before {
  content: "";
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: var(--color-ocre-dark);
  position: absolute;
  transform: rotate(45deg);
  top: 9px;
  left: 5px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
}
.checkbox-wrapper label.important span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: var(--color-ocre-dark);
  position: absolute;
  transform: rotate(305deg);
  top: 12px;
  left: 6px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
.sidebar.filtres .checkbox-wrapper {
  margin-bottom: 5px;
}

.sidebar.filtres .checkbox-wrapper label {
  line-height: 1.2;
  padding-right: 15px;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
}
.checkbox-wrapper input[type="checkbox"]:checked + label span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}
.checkbox-wrapper input[type="checkbox"]:checked + label.important span {
  background-color: var(--color-ocre-dark);
  transform: scale(1.1);
}
.checkbox-wrapper input[type="checkbox"]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper input[type="checkbox"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper input[type="checkbox"]:checked + label:hover span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}
.checkbox-wrapper input[type="checkbox"]:checked + label.important:hover span {
  background-color: var(--color-ocre-dark);
  transform: scale(1.1);
}
.checkbox-wrapper input[type="checkbox"]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-wrapper input[type="checkbox"]:checked + label:hover span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.sidebar-checkbox a.link {
  color: var(--color-primary-3);
  text-decoration: none;
  background: linear-gradient(to right, var(--color-primary-3), var(--color-primary-3)), linear-gradient(to right, var(--color-primary-4), var(--color-primary-4));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  height: 32px;
  display: inline-block !important;
  line-height: 32px;
  margin-top: 5px;
  font-size: 15px;
  font-family: "Barlow semi Condensed";
}

.sidebar-checkbox a.link:hover {
  background-size: 0 3px, 100% 3px;
}

.form-checkbox__mark {
  border-radius: 0;
}

.radio-wrapper .radio {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.radio-wrapper label {
  display: inline-block;
  color: var(--color-primary-4);
  cursor: pointer;
  position: relative;
  font-family: "Barlow Condensed";
  font-size: 16px;
}

.radio-wrapper label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 2px solid var(--color-primary-4);
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 10px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  top: -2px;
}
.radio-wrapper label span:before {
}

.radio-wrapper label span:after {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 10px;
  background: var(--color-primary-4);
  position: absolute;
  transform: rotate(305deg);
  top: 9px;
  left: 2px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
}
.radio-wrapper label:hover span:before {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  transition: width 100ms ease;
}
.radio-wrapper label:hover span:after {
  width: 9px;
  height: 9px;
  border-radius: 10px;
  transition: width 150ms ease 100ms;
}

.radio-wrapper input[type="radio"] {
  display: none;
}
.radio-wrapper input[type="radio"]:checked + label span {
  background-color: var(--color-primary-4);
  transform: scale(1.1);
}
.radio-wrapper input[type="radio"]:checked + label span:after {
  width: 9px;
  background: #fff;
  transition: width 150ms ease 100ms;
  height: 9px;
}
.radio-wrapper input[type="radio"]:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
// .radio-wrapper input[type="radio"]:checked + label:hover span {
//   background-color: var(--color-primary-4);
//   transform: scale(1.1);
// }
// .radio-wrapper input[type="radio"]:checked + label:hover span:after {
//   width: 10px;
//   background: #fff;
//   transition: width 150ms ease 100ms;
// }
// .radio-wrapper input[type="radio"]:checked + label:hover span:before {
//   width: 9px;
//   height: 9px;
//   background: #fff;
//   transition: width 150ms ease 100ms;
// }

.sidebar__item:not(.-no-border) {
  border: none;
}

.sidebar-checkbox .col-auto {
  // min-width: 200px;
}

.noUi-handle {
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  top: calc(50% + 2px) !important;
  transform: translateY(-50%);
  border: 2px solid var(--color-primary);
  box-shadow: none;
  background: var(--color-primary);
  cursor: pointer;
}

.noUi-target {
  background: #e3e8e7;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  outline: none;
  margin-right: 10px;
}

.noUi-connect {
  background-color: var(--color-primary) !important;
  box-shadow: none !important;
}

.form-switch {
  padding-left: 0;
}

.form-switch .switch input {
  cursor: pointer;
}

.form-switch .switch__slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  border-radius: 30px;
  border: 2px solid var(--color-primary);
}

.form-switch .switch__slider::before {
  position: absolute;
  cursor: pointer;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: var(--color-primary);
  border-radius: 50%;
  transition: 0.3s;
}

.form-switch .switch input:checked + .switch__slider {
  background-color: var(--color-primary);
}

.form-switch .switch {
  position: relative;
  min-width: 50px;
  height: 30px;
  cursor: pointer;
}

/******* CONTENUS ********/

.bubble {
  width: 150px;
  height: 160px;
  font-size: 17px;
  color: var(--color-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.bubble i[class^="fi-rr-"]:before,
.bubble i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: 32px;
}

.bafad .bubble {
  width: 180px;
  height: 160px;
  font-size: 17px;
  color: var(--color-white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.bafad .departs h5 {
  font-size: 16px;
}

.bubble.geo {
  padding: 15px 20px 20px 20px;
  text-align: center;
  line-height: 18px;
}

.bubble.geo i {
  margin-bottom: 10px;
  display: inline-block;
}

.bubble.type {
  padding: 15px 20px 20px 20px;
  text-align: center;
  line-height: 18px;
}

.bubble.type i {
  margin-bottom: 10px;
  display: inline-block;
}

.bubble.team {
  background-image: url("../img/contenus/bubble-team.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.age {
  background-image: url("../img/contenus/bubble-age.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.type {
  background-image: url("../img/contenus/bubble-type.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.geo {
  background-image: url("../img/contenus/bubble-geo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.thematiquescolaire {
  background-image: url("../img/contenus/bubble-geo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.ssthematiquescolaire {
  background-image: url("../img/contenus/bubble-ssthematiquescolaire.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble.hebergement {
  background-image: url("../img/contenus/bubble-hebergement.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bubble .badge-thematique {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 9px;
  display: inline-block;
  margin: 2px;
  margin-top: 6px;
}

.bubble .badge-thematique i {
  margin-bottom: 0px;
  display: inline-block;
}

.bubble .badge-thematique i:before {
  font-size: 18px;
  top: auto !important;
  margin-right: 0;
}

.tooltip-inner {
  background-color: var(--color-primary);
  box-shadow: none;
  opacity: 1 !important;
  color: var(--color-primary-2) !important;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  padding: 10px;
  max-width: 280px;
}
.tooltip.show {
  opacity: 1 !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: var(--color-primary) !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: var(--color-primary) !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--color-primary) !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--color-primary) !important;
}

.bs-tooltip-auto .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-color: var(--color-primary) !important;
}

.thematique {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  max-width: 100px;
  line-height: 20px;
  text-align: center;
}

.thematique i[class^="fi-rr-"]:before,
.thematique i[class*=" fi-rr-"]:before {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.thematique img {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -5px;
  margin-bottom: 10px;
}

.detail-thematique h3 i:before {
  font-size: 80px;
}

.categorie {
  padding: 5px 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-white) !important;
}

.categorie.sejour {
  background: var(--color-primary-3);
}

.categorie.bafa {
  background: var(--color-terracotta);
}

.categorie.educform {
  // background: #a4bf59;
  background: #44bb97;
}

.categorie.hebergement {
  background: #d67164;
}

.categorie.sejourscolaire {
  background: #d68d64;
}

.transport {
  color: var(--color-primary-4) !important;
  position: relative;
  padding-left: 0px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  // flex: 1 0 31%;
}

.circle {
  background: var(--color-primary-1) !important;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.transport i[class^="fi-rr-"]:before,
.transport i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.centre {
  width: 200px;
  height: 200px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centre img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

ul.documents {
  padding: 30px;
  background: var(--color-bloc) !important;
}

.depart {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../img/backgrounds/bg-depart.svg");
  background-position: top;
  min-width: 263px;
}

.condition {
  color: var(--color-primary-4) !important;
}

.link-externe {
  color: var(--color-primary-4) !important;
  margin: 20px 0;
}

.information {
  color: var(--color-text) !important;
  background: var(--color-bloc) !important;
  position: relative;
  padding-left: 0px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
}

.information i[class^="fi-rr-"]:before,
.information i[class*=" fi-rr-"]:before {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
}

.informations h4 {
  color: var(--color-primary-3) !important;
  padding: 5px 0;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}

.accessibilite i[class^="fi-rr-"]:before,
.accessibilite i[class*=" fi-rr-"]:before {
  font-size: 40px;
}

.accordion h5 {
  font-family: "Barlow Condensed";
}

.accordion__item {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
}

.accordion.-map .accordion__icon {
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-button::after {
  width: auto;
  height: auto;
  background-image: none;
  font-family: "uicons-bold-straight" !important;
  content: "\f150";
  font-size: 20px;
}

.total {
  font-size: 24px;
  color: var(--color-terracotta) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.confirmation p {
  font-family: "Sen", sans-serif;
  font-weight: 700;
}

.confirmation .bg-bloc {
  background-color: #eaefef !important;
}

.confirmation,
.confirmation .info {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.confirmation h5 {
  color: var(--color-primary-4);
  font-size: 18px;
  font-style: normal;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.confirmation .total {
  font-size: 20px;
  color: var(--color-primary) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.confirmation .solde {
  font-size: 16px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 26px;
}

.col-auto.option {
  max-width: 440px;
}

.suggestions {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(../img/backgrounds/bg-wave-light.svg);
  background-position: 0px 0px;
  padding-top: 100px;
  margin-top: 100px;
}

.etape {
  font-size: 18px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.etape .col-auto {
  line-height: 22px;
}

.etape .actif {
  color: var(--color-primary-3) !important;
}

.etape .actif .rounded-full {
  background: var(--color-primary-2) !important;
}

.etape .inactif {
  color: var(--color-primary-2) !important;
}

.etape .inactif .rounded-full {
  background: var(--color-primary-1) !important;
  color: var(--color-primary-2);
}

.etape .courant .rounded-full {
  background: var(--color-primary-4) !important;
}

.etape-line {
  border-bottom: 2px dashed var(--color-primary-2) !important;
}

.minibloc {
  max-width: 250px;
}

.page {
  border: 1px solid #c0d5da;
  border-radius: 4px;
  padding: 0 8px;
  margin-top: 5px;
}

.telechargement {
  // gap: 20px;
}

.commodites {
  padding: 30px;
  background: var(--color-bloc);
}

.commodites .divider {
  margin: 30px 0;
}

.commodites h4 {
  margin: 20px 0;
  font-size: 30px;
}
.commodites i:before {
  font-size: 50px;
  padding-right: 10px;
}

.commodites .lesplus {
  color: var(--color-ocre-dark) !important;
}

.commodites .lesplus h4,
.commodites .lesplus ul li {
  color: var(--color-ocre-dark) !important;
}

.step {
  padding: 15px 40px;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 26px;
  color: var(--color-primary-4) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
}

.accordion-button i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  font-size: 40px;
  margin-right: 8px;
}

.processus-reservation .heading .curve {
  position: relative;
  display: block;
  height: 140px;
  bottom: -140px;
  margin-top: -140px;
  overflow: hidden;
}

.processus-reservation .steps i[class*=" fi-ss-"]:before {
  vertical-align: middle;
  top: 0;
  position: relative;
  font-size: 36px;
}

.processus-reservation .steps .divider {
  margin: 25px auto 20px auto;
  padding: 0px;
  width: calc(100% - 2px);
}

.processus-reservation .info.payeur {
  color: var(--color-light-1);
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
  margin-top: 10px;
}

// .processus-reservation .divider-participant {
//   height: 10px;
//   width: 100%;
//   background: var(--color-primary-3);
//   border: none;
// }

.processus-reservation .titre-participant {
  font-family: "Barlow Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  padding: 5px 20px;
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin: 30px 0;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--color-primary-4) !important;
  background-color: var(--color-primary-2);
  box-shadow: none;
}

.accordion-item {
  background-color: #fff;
  border: none;
}

.processus-reservation h2.accordion-header {
  margin-bottom: 0;
  line-height: 30px;
}

.processus-reservation .connexion {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 20px;
  row-gap: 10px;
}

.processus-reservation h3:not(.tag-title) {
  font-size: 22px;
  color: var(--color-primary-3) !important;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-top: 10px;
}

.processus-reservation .steps .form-input label {
  top: 22px;
}

.recrutement .form-input label {
  top: 22px;
}

.recrutement .encart .form-input label {
  top: 30px;
}

.processus-reservation .steps .form-input .date {
  position: absolute;
  right: 15px;
  top: 15px;
}

.processus-reservation .steps .form-input .date i:before {
  font-size: 24px;
}

.processus-reservation .encart .titre,
.publication .encart .titre {
  padding: 15px 30px;
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin-bottom: 20px;
}

.processus-reservation .encart h5,
.publication .encart h5 {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.processus-reservation .encart .tarif,
.publication .encart .tarif {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  color: var(--color-primary-4) !important;
}

.processus-reservation .encart .info,
.publication .encart .info {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
}

.processus-reservation .encart .remise,
.publication .encart .remise {
  color: var(--color-primary-3) !important;
}

.obligatoire {
  color: var(--color-terracotta) !important;
}

.processus-reservation .steps .select.small button {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 22px 15px 22px 15px;
  line-height: 10px;
  height: auto;
}

.processus-reservation .steps .select.small .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-top: 20px;
}

.processus-reservation .steps .select.small .js-button-title {
  padding-top: 0;
}

.processus-reservation.devis .select button {
  height: 63px;
}

.processus-reservation.devis .link-externe {
  color: var(--color-primary-l) !important;
  margin: 20px 0;
  background-color: var(--color-primary-4) !important;
}

.processus-reservation.devis .link-externe h5 {
  color: var(--color-primary-1) !important;
  padding: 5px 20px;
  font-size: 18px;
}

/********** MAP **********/

#map {
  height: 500px;
  width: 100%;
  clip-path: ellipse(100% 55% at 48% 44%);
  margin: 0;
  transition: 0.3s;
}

#map.itinerance {
  height: 100%;
  width: 100%;
  margin: 0;
  clip-path: none;
  min-height: 300px;
}

.vignette.map-info .gclose {
  width: 30px;
}

.vignette.map-info h4 {
  font-size: 24px;
  line-height: 18px !important;
  margin: 0;
  font-size: 18px !important;
}

.gm-style-moc {
  opacity: 0 !important;
}

.gm-style-iw-t {
  right: unset !important;
  left: 0 !important;
  bottom: 53px !important;
}

.gm-style-iw-chr {
  display: none;
}
.gm-style .gm-style-iw-d {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto !important;
  max-width: 400px;
}

.gm-style .gm-style-iw-c {
  background-color: var(--color-white) !important;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

.zoomin {
  background: var(--color-primary-4) !important;
  padding: 12px !important;
  color: white !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-right: 20px;
  margin-bottom: 0px;
}

.zoomout {
  background: var(--color-primary-4) !important;
  padding: 12px !important;
  color: white !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-right: 20px;
  margin-bottom: 60px;
}

.lieu .zoomin,
.hebergements .zoomin {
  margin-right: 20px;
  margin-bottom: 0px;
}

.lieu .zoomout,
.hebergements .zoomout {
  margin-right: 20px;
  margin-bottom: 10px;
}

.zoomin:hover,
.zoomout:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15) !important;
}

.zoomin i[class^="fi-rr-"]:before,
.zoomin i[class*=" fi-rr-"]:before,
.zoomin span[class^="fi-rr-"]:before,
.zoomin span[class*="fi-rr-"]:before,
.zoomout i[class^="fi-rr-"]:before,
.zoomout i[class*=" fi-rr-"]:before,
.zoomout span[class^="fi-rr-"]:before,
.zoomout span[class*="fi-rr-"]:before,
.zoomin i[class*="fi-bs-"]:before,
.zoomout i[class*="fi-bs-"]:before {
  margin-right: 0px;
  top: 0;
  font-size: 16px;
}

// .gmnoprint {
//   left: -40px;
//   top: -40px;
// }

// .flex-container {
//   display: flex;
// }

// aside {
//   position: sticky;
//   top: 80px;
//   background: rgb(255, 100, 100);
//   padding: 20px;
//   width: 20%;
//   height: 500px;
//   margin-bottom: 20px;
// }

// main {
//   padding-left: 20px;
//   flex-grow: 1;
// }

// .content {
//   background: rgb(200, 200, 200);
//   height: 300px;
//   padding: 20px;
//   margin-bottom: 20px;
// }

/******* VIGNETTES ********/

.swiper-wrapper {
  // height: auto !important;
}

.swiper-slide {
  background-color: white;
  // padding-bottom: 20px;
  width: 300px !important;
  height: auto;
}

.swiper-autoheight .swiper-slide {
  height: 100%;
}

.swiper-slide p,
.swiper-slide p i {
  color: var(--color-light-1);
}

.swiper-slide h4 {
  color: var(--color-primary-4) !important;
}

// .slider-hebergements .swiper-slide {
//   background-color: white;
//   padding-bottom: 20px;
//   width: 100% !important;
//   height: auto;
// }

p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 175px;
}

.encart p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
}

.swiper-slide p.last-chance,
main .informatif .swiper-slide p.last-chance {
  color: var(--color-ocre-dark) !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px !important;
  border: 1px solid var(--color-ocre-dark);
  padding: 2px 10px;
  border-radius: 50px;
  width: 175px;
}

main .informatif .swiper-slide p .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-primary-4) !important;
  color: var(--color-primary-l);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.nav-pills h5 {
  font-family: "Barlow Condensed";
}

.nav-pills .nav-link.active h5 {
  font-family: "Barlow Condensed";
  color: var(--color-white);
}

.nav-pills .accordion__icon {
  background-color: var(--color-primary-4) !important;
  color: var(--color-white);
  font-family: "Barlow Condensed" !important;
  font-style: normal;
  font-weight: 600;
}

.nav-pills .nav-link.active .accordion__icon {
  background-color: var(--color-white) !important;
  color: var(--color-primary-4);
}

.flex-parent {
  display: flex;
}

.flex-item {
  width: 100%;
}

.hebergements .photo-centre {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.vignette {
  font-family: "Barlow Condensed", sans-serif;
}

.resultats .vignette,
.resultats-multi .vignette {
  font-family: "Barlow Condensed", sans-serif;
  background-color: #f3f4f4;
  // padding-bottom: 15px;
  height: 100%;
  display: block;
  transition: 0.3s;
}

.resultats .vignette,
.resultats-multi .vignette:hover {
  background-color: #f8f8f8;
  transition: 0.3s;
}

.vignette .info {
  color: var(--color-light-1);
  font-size: 12px;
  line-height: 13px;
  width: 80px;
  text-align: right;
  margin-top: -5px;
}

.resultats-multi .swiper-slide {
  background-color: #f3f4f4;
}

.resultats-multi.thematiques .swiper-slide {
  background-color: transparent;
  width: 150px !important;
}

.resultats-multi.thematiques .info {
  line-height: 17px;
  display: block;
  text-align: center;
}

.resultats-multi.thematiques .swiper-slide .info {
  line-height: 17px;
  display: inline-block;
  text-align: center;
}

.resultats-multi h3 {
  color: var(--color-primary-4) !important;
}

.sejourscolaire .resultats-multi h3 {
  color: var(--color-ocre-dark) !important;
}

.sejourscolaire .hebergements h4 {
  font-size: 24px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin: 10px 0 20px 0;
}

.resultats-multi h3.tag-title {
  color: #f0f0f0 !important;
}

.resultats-multi h4 {
  font-size: 18px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-top: 5px;
}

.resultats-multi .mainSearch h4 {
  color: var(--color-primary-4) !important;
}

#resultats-contenu .titre h4 {
  font-size: 18px;
  color: var(--color-primary-3) !important;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  margin-top: 5px;
}

.resultats-multi .vignette h4 {
  color: var(--color-primary-4) !important;
  margin: 0;
  padding: 10px 0;
}

.resultats.recrutement h4 {
  font-size: 18px;
}

.resultats-unitaire.hebergement .heading .curve {
  z-index: 1;
}

.recrutement h2 {
  color: var(--color-primary-3) !important;
}

.recrutement .vignette .typeposte {
  color: var(--color-ocre-dark) !important;
  padding: 4px 0;
}

.suggestions .vignette h4 {
  margin: 0;
}

.actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: #f1f5e2 !important;
}

.actualites .swiper-slide {
  background-color: transparent;
  // padding-bottom: 20px;
  width: 300px !important;
  height: auto;
}

.actualites .swiper-slide p {
  line-height: 18px;
  color: var(--color-primary-2) !important;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actualites .swiper-slide p {
  color: var(--color-primary-2) !important;
  margin: 10px 0;
  line-height: 24px;
  font-family: "Sen", sans-serif;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
}

.actualites .swiper-slide p.auteur {
  line-height: 18px;
  color: var(--color-primary-3) !important;
  margin: 10px 0;
}

.actualites .swiper-slide p.auteur i {
  color: var(--color-primary-3) !important;
}

.actualites .swiper-slide .bandeau {
  background: transparent !important;
}

.actualites .swiper-slide .tag {
  background: var(--color-primary-3) !important;
}

.actualites .swiper-slide .tag-tail:before,
.actualites .swiper-slide .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid var(--color-primary-3) !important;
}

.actualites .swiper-slide .tag-tail:after,
.actualites .swiper-slide .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--color-primary-3) !important;
}

.actualites .swiper-slide .vignette__content {
  padding: 0;
}

.actualites .vignette.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 200px;
}

.informatif .actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: var(--color-primary-3) !important;
}

.informatif .actualites .swiper-slide p {
  color: var(--color-light-1) !important;
}

.informatif .actualites .swiper-slide .tag {
  background: var(--color-primary-3) !important;
  color: white !important;
}

.informatif .actualites .swiper-slide h3.tag-title {
  color: white !important;
}

.educform .vignette h4,
.recrutement .vignette h4 {
  margin: 0;
  padding-bottom: 0px !important;
}

.educform .vignette h4 {
  margin: 5px 0;
}

.recrutement .swiper-slide h4 {
  font-size: 18px;
}

.vignette.-type-1 .vignette__image {
  overflow: hidden;
  border-radius: 0px;
  position: relative;
  height: 250px;
}

.vignette__content {
  padding: 0 25px;
  height: calc(100% - 35px);
  padding-bottom: 20px !important;
}

.vignette.-type-1:has(> .vignette__image img) .vignette__content {
  height: calc(100% - 250px);
}

.actualites .vignette.-type-1:has(> .vignette__image img) .vignette__content {
  height: calc(100% - 200px);
}

.vignette.-type-1 .vignette__image .cardImage__content .swiper-slide,
.vignette.-type-1 .vignette__image .cardImage__content > img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.vignette .cardImage {
  width: 100%;
  height: 100%;
}

.vignette .cardImage__wishlist div {
  padding: 2px 10px;
  background: var(--color-primary-3) !important;
  color: var(--color-white) !important;
  font-size: 13px;
  border-radius: 100px;
}

.vignette .cardImage__wishlist {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  left: auto;
}

.vignette.map-info {
  position: relative;
}

.vignette.map-info .content {
  padding-right: 10px;
}

.vignette.map-info i {
  margin-top: -3px;
}

.vignette.map-info .tag {
  position: absolute;
  top: 10px;
}

.vignette.map-info .tarif {
  font-size: 15px;
}

.vignette.map-info .type {
  padding: 2px 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
  line-height: 17px;
}

.vignette.map-info .lieu {
  padding: 5px 0;
  font-size: 14px;
  text-transform: none;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 14px;
}

// This is the overall color of the tag
$tag-color: var(--color-ocre-dark) !important;

// The tag itself
.tag {
  min-width: 50px;
  height: 30px;
  background-color: var(--color-ocre-dark) !important;
  position: relative;
  padding: 0 10px;
  display: inline-block;
}

// The title of the tag
.tag-title {
  line-height: 30px;
  color: #fff;
  font-size: 15px;
}

.tag-title i:before {
  color: #fff;
}

// The tails of the tag
.tag-tail,
.tag-left .tag-tail {
  position: absolute;
  top: 0;
  right: -10px;
}
.tag-center .tag-tail-left {
  position: absolute;
  top: 0;
  left: -10px;
}

[class^="tag-tail"]:after,
[class^="tag-tail"].tag-tail:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
}

.tag-tail:after,
.tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid $tag-color;
}

.tag-tail:before,
.tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid $tag-color;
}

.vignette.-type-1 .vignette__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.vignette.-type-1 .vignette__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.vignette.-type-1:hover .vignette__image .cardImage__content > img,
.vignette.-type-1:hover .vignette__image .swiper-slide {
  transform: scale(1.15);
}

.vignette.-type-1:hover .vignette__title span {
  background-size: 100% !important;
}

.vignette .bandeau {
  font-size: 14px;
  padding: 5px 25px;
  background: var(--color-primary-3) !important;
  color: var(--color-white) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.resultats-multi.thematiques .bubble {
  text-align: center;
  line-height: 18px;
  font-size: 16px;
  padding: 20px 15px;
  text-transform: none;
}

.resultats-multi.thematiques .bubble .theme {
  // padding-top: 5px;
}

.resultats-multi.thematiques .bubble i[class^="fi-rr-"]:before,
.resultats-multi.thematiques .bubble i[class*=" fi-rr-"]:before {
  top: -8px;
  // top: 35px;
  // position: absolute;
  // margin-right: 5px;
  // font-size: 32px;
  // left: 55px;
}

.vignette i[class^="fi-rr-"]:before,
.vignette i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-right: 5px;
  font-size: inherit;
}

.vignette .organisation i[class^="fi-rr-"]:before,
.vignette .organisation i[class*=" fi-rr-"]:before {
  vertical-align: middle;
  top: 0px;
  position: relative;
  margin-right: 0px;
  font-size: 10px;
}

.vignette h4 {
  font-size: 24px;
  line-height: 22px !important;
  margin: 0;
}

.vignette .type {
  padding: 5px 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 4px;
  display: inline-block;
  line-height: 17px;
}

.vignette .tarif {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-terracotta) !important;
}

.vignette .lieudepart {
  font-size: 13px;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  font-family: "Barlow Condensed", sans-serif;
  line-height: 15px;
}

.vignette .modalite {
  font-size: 15px;
  font-weight: 700;
  color: #d68d64 !important;
  font-family: "Barlow Condensed", sans-serif;
}

.vignette .organisation {
  font-size: 15px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  margin-top: 10px;
}

.vignette .partenariat {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-light-1) !important;
  line-height: 1;
  font-style: italic;
  margin-top: 0px;
}

.vignette .partenariat a {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-light-1) !important;
  line-height: 1;
  font-style: italic;
  text-decoration: underline;
  margin-top: 0px;
}

.vignette ul.equipements li {
  color: var(--color-primary-3) !important;
  list-style: none;
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
}

.vignette ul.equipements li:before {
  font-family: uicons-regular-rounded !important;
  position: absolute;
  left: -7px;
  color: #d67164 !important;
  font-size: 26px;
  content: "\f305";
}

.pagination.-dots .pagination__item.is-active {
  background-color: var(--color-primary-4);
  transform: scale(1);
  margin-top: 10px;
}

.pagination.-dots .pagination__item {
  margin-top: 10px;
}

.vignettes-nav {
  margin-top: 10px;
  color: var(--color-primary-3);
}

.vignettes-nav.swiper-button-disabled {
  color: var(--color-border);
  transform: none;
}

.vignettes-nav.arrow-right-hover:hover,
.vignettes-nav.arrow-left-hover:hover {
  color: var(--color-primary-4);
  transform: translateX(5px) scale(1.25);
}

.vignettes-nav.swiper-button-disabled.arrow-right-hover:hover,
.vignettes-nav.swiper-button-disabled.arrow-left-hover:hover {
  color: var(--color-border);
  transform: none;
}

.bafa .vignette .bandeau {
  background: var(--color-terracotta) !important;
}

.bafad .thematique {
  padding: 15px;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-3) !important;
  border: 2px solid var(--color-primary-3) !important;
  border-radius: 10px;
  width: auto;
  display: inline-block;
  max-width: fit-content;
  margin-top: 8px;
}

.bafad .dateformation {
  border: none;
  background: #e3e8e7;
  padding: 12px 15px;
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 21px;
}

.bafad .dateformation .duree {
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.scolaire .vignette .bandeau {
  background: #d68d64 !important;
}

.hebergement .vignette .bandeau {
  background: #d67164 !important;
}

.recrutement .vignette .bandeau {
  background: #5e6d92 !important;
}

.educform .vignette .bandeau.citoyennete {
  // background: #74b7cf !important;
  background: #44bb97 !important;
}

.educform .vignette .bandeau.bienetre {
  // background: #a4bf59 !important;
  background: #44bb97 !important;
}

.vignette .type.citoyennete {
  background: #74b7cf !important;
  border: 2px solid #74b7cf !important;
  // background: #44bb97 !important;
  // border: 2px solid #44bb97 !important;
  color: white !important;
}

.vignette .type.bienetre {
  background: #a4bf59 !important;
  border: 2px solid #a4bf59 !important;
  // background: #44bb97 !important;
  // border: 2px solid #44bb97 !important;
  color: white !important;
}

.educform .vignette .bandeau.animation {
  background: #d68d64 !important;
}

.vignette .type.animation {
  color: #d68d64 !important;
  border: 2px solid #d68d64 !important;
}

.educform .vignette.-type-1 .vignette__image,
.recrutement .vignette.-type-1 .vignette__image {
  height: 35px;
}

.recrutement .vignette .type {
  color: #5e6d92 !important;
  border: 2px solid #5e6d92 !important;
}

.recrutement .vignette .date {
  color: var(--color-primary-3) !important;
}

.resultats.actualites .vignette {
  background-color: transparent;
  padding-bottom: 20px;
  width: auto !important;
  height: auto;
}

.resultats.actualites .vignette .vignette__content {
  padding: 0;
}

.resultats.actualites .vignette h4 {
  margin: 15px 0 10px 0;
  color: var(--color-primary-4) !important;
}

.resultats.actualites.publications .vignette h4 {
  margin: 0;
}

.resultats.actualites .vignette p {
  color: var(--color-light-1) !important;
  margin: 10px 0;
  line-height: 24px;
  font-family: "Sen", sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.resultats.actualites .vignette p.auteur {
  color: var(--color-primary-3) !important;
  margin: 5px 0;
  font-family: "Barlow condensed", sans-serif;
  font-size: 16px;
}

.resultats.actualites .vignette p.auteur i:before {
  color: var(--color-primary-3) !important;
  margin: 5px 0;
}

.actualites .tag {
  background: var(--color-primary-3) !important;
}

.actualites .tag-tail:before,
.actualites .tag-left .tag-tail:before {
  border-right: 10px solid transparent;
  border-top: 15px solid var(--color-primary-3) !important;
}

.actualites .tag-tail:after,
.actualites .tag-left .tag-tail:after {
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--color-primary-3) !important;
}

/******* TABLES ******/

table.table-2 td:last-child {
  text-align: left;
  padding-right: 0;
  max-width: 250px;
}

table.table-2 th:last-child {
  text-align: left;
  padding-right: 0;
  max-width: 250px;
}

table.table-4 tbody tr:first-child {
  background-color: #e4f0ef !important;
}

/******* ESPACE ENSEIGNANT ******/

.enseignant .nav-pills .nav-link {
  border-radius: 0;
  background: var(--color-bloc);
  margin-right: 10px;
}

.enseignant .nav-pills .accordion__icon {
  background-color: transparent !important;
  color: var(--color-primary-4);
}

.enseignant .nav-pills .accordion__icon i[class*=" fi-rr-"]:before {
  background-color: transparent !important;
  color: var(--color-primary-4);
  font-size: 22px !important;
}

.enseignant .nav-pills .nav-link.active .accordion__icon {
  background-color: transparent !important;
  color: var(--color-primary-1);
}

.enseignant .nav-pills .nav-link.active .accordion__icon i[class*=" fi-rr-"]:before {
  background-color: transparent !important;
  color: var(--color-primary-1);
  font-size: 22px !important;
}

/******* ANIMATIONS ********/

[data-anim^="slide-up"],
[data-anim-child^="slide-up"] {
  transform: translate3d(0, 60px, 0);
}

/****** MODAL ***********/

.modal-footer {
  border-top: none;
  background: #eaf2f2;
  border-radius: 0;
  padding: 10px 20px !important;
}

.modal-footer button.button-outline {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  color: var(--color-primary-4) !important;
  border: 2px solid var(--color-primary-4) !important;
  padding: 8px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin: 5px 0;
}

.modal-footer button.button-outline:hover {
  color: var(--color-white) !important;
}

.modal-footer button.button-primary {
  padding: 8px 20px;
  border-radius: 200px;
  transition: 0.3s;
  white-space: nowrap;
  text-align: center;
  margin: 5px 0;
}

.modal .form-input label {
  top: 22px;
}

.modal-header {
  border-bottom: none;
  border-radius: 0;
}

.modal-content {
  border-radius: 0;
}

/****** FEEDBACKS ********/

.alert-success p,
.alert-success h4 {
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
  line-height: 22px;
}

// .alert-danger {
//   background: var(--color-danger) !important;
// }

.alert-danger h4,
.alert-danger p {
  color: var(--color-terracotta) !important;
  margin-bottom: 0;
}

.alert-info {
  background: var(--color-primary-1) !important;
}

.alert-info h4,
.alert-info p {
  color: var(--color-primary-4) !important;
  margin-bottom: 0;
}

.alert-success,
.alert-danger,
.alter-warning,
.alert-info {
  margin-top: 40px;
  border-radius: 0;
  border: none;
  padding: 30px;
}

.f-requis {
  color: var(--color-terracotta) !important;
  font-size: 14px;
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.encart .f-requis {
  padding: 0;
  margin-top: -10px;
  margin-left: 15px;
  margin-bottom: 6px;
}

.hebergement .encart .f-requis {
  padding: 0;
  margin-top: -5px;
  margin-left: 15px;
  margin-bottom: 6px;
}

.hebergement .encart .select.libelle label {
  display: inline-block;
  position: absolute;
  top: 4px;
  color: var(--color-primary-3);
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.hebergement .encart .select.libelle .js-button-title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  padding-top: 22px;
  line-height: 15px;
}

input[type="number"]::-webkit-inner-spin-button {
  margin-top: -10px !important;
  color: var(--color-primary-4) !important;
}

.form-input input.requis,
.form-input textarea.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.input-group.form-input .btn-primary {
  background: var(--color-primary-4) !important;
  color: var(--color-white) !important;
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  line-height: 0.8;
  border-radius: 0;
  border: none;
  padding: 20px;
}

.input-group.form-input .btn-primary:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}

.select button.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.select.bafa {
  border-radius: 0px;
  border: none;
  background: #e3e8e7;
  padding: 10px 15px 10px 15px;
}

.select.libelle .title {
  color: var(--color-primary-4);
  font-family: "Barlow Condensed";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  display: block;
  line-height: 25px;
  padding-top: 0;
}

.hebergement .select.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.select.requis {
  border: 2px solid var(--color-terracotta) !important;
}

.checkbox-wrapper label.requis {
  color: var(--color-terracotta);
}

.radio-wrapper label.requis {
  color: var(--color-terracotta);
}

.checkbox-wrapper label.requis span {
  border: 2px solid var(--color-terracotta);
}

.radio-wrapper label.requis span {
  border: 2px solid var(--color-terracotta);
}

button.appliquer {
  width: 120px !important;
  line-height: 40px;
  border-radius: 0;
}

.form-input.appliquer {
  min-width: 260px;
}

/****** RESEAUX SOCIAUX ********/

#app {
  background: red !important;
  color: red !important;
}

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Barlow condensed", sans-serif !important;
  color: red !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: transparent;
  border-radius: 0px !important;
  border-style: none;
  border-width: 0px;
  box-shadow: none;
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}

.fb_iframe_widget {
  border: 10px solid var(--color-primary-3) !important;
  border-radius: 20px !important;
}

._2lqg {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: none !important;
  padding: 20px;
}

._1drm {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: none !important;
  padding: 20px;
}

._1dro ._1drp {
  color: #1b706a;
  display: inline-block;
  font-size: 18px;
  font-weight: 700 !important;
  line-height: 1.358;
  margin-bottom: -5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._1drm ._1drn {
  border: none;
  margin-right: 5px;
}

._1dro ._1drq {
  color: #5f9c99 !important;
  font-size: 12px;
  line-height: 1.358;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/******* ADAPTATIONS RESPONSIVE ********/

/******* MIN ********/

@media screen and (min-width: 576px) {
  .footer .container,
  .footer .container-sm {
    // max-width: fit-content;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-none {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  // .container,
  // .container-sm {
  //   max-width: auto;
  // }
  .footer .container,
  .footer .container-sm {
    // max-width: fit-content;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    max-width: 1200px !important;
  }
  .gslide iframe,
  .gslide video {
    max-width: 1200px !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .footer .container,
  .footer .container-sm {
    // max-width: fit-content;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header.is-menu-opened .container {
    max-width: inherit;
    margin: 0;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .header .header-menu .menu .mega {
    min-width: 480px;
    width: auto;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: calc(100% - 15px);
    background-color: white;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .header .header-menu .menu .subnav > li > a {
    padding: 5px 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
  }

  .header .header-menu .menu .subnav > li > a:hover {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
  }

  .header .header-menu .menu .mega__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto auto auto auto;
  }

  .header .header-menu .menu .mega__grid.niv3 {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto auto;
    min-width: 580px;
  }

  .header .header-menu .menu .mega {
    position: absolute;
    top: calc(100% - 15px);
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .menu .icon-chevron-sm-down {
    display: none;
  }
  .header .header-menu .menu__nav > li .mega::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: var(--color-bloc) !important;
    transform: rotate(45deg);
  }

  .header .header-menu .menu .mega a {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
    line-height: 1.2;
  }

  .header .header-menu .menu .mega .n1 a {
    padding: 10px 20px 10px 15px;
    border-radius: 0px;
    color: var(--color-ocre-dark) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
  }

  .header .header-menu .menu .mega .n1 a:hover {
    color: var(--color-white) !important;
    cursor: pointer;
    background-color: var(--color-ocre-dark) !important;
  }

  .header .header-menu .menu .mega .n2 a {
    padding: 8px 15px 8px 15px;
    font-size: 15px;
  }

  .header .header-menu .menu .mega .n2 a:hover {
    cursor: pointer;
    background-color: var(--color-primary-2) !important;
  }

  .header .header-menu .menu .subnav > li.n2 a {
    padding: 5px 15px 5px 15px;
    font-size: 15px;
  }

  .header .header-menu .menu .mega .n1 a:hover {
  }

  .header .header-menu .menu .mega .n3 a {
    padding: 5px 20px 5px 15px;
    font-size: 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: none;
  }

  .header .header-menu .menu .mega .n3 a:hover {
    color: var(--color-primary) !important;
  }

  .mega-menu {
    width: 100%;
    margin-top: 90px;
    padding: 10px 40px;
    background-color: var(--color-bloc) !important;
    position: fixed;
    z-index: 999999;
  }

  // .mega-menu.recherche {
  //   width: 100%;
  //   margin-top: 90px;
  //   padding: 10px 40px;
  //   background-color: var(--color-bloc) !important;
  //   position: absolute;
  //   top: 0;
  //   z-index: 99999;
  // }

  .mega-menu .subnav {
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .mega-menu .subnav > li > a {
    padding: 5px 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
  }

  .mega-menu .subnav > li > a:hover {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
  }

  .mega-menu .mega__grid {
    display: grid;
    gap: 40px 20px;
    grid-template-columns: auto auto auto auto auto;
    // grid-auto-columns: minmax(0, 1fr);
    // grid-auto-flow: column;
  }

  // .mega-menu .mega__grid.niv3 {
  //   display: grid;
  //   gap: 40px 20px;
  //   grid-template-columns: auto auto auto auto;
  // }

  .mega-menu .mega__grid.c4 {
    grid-template-columns: auto auto auto auto;
  }

  .mega-menu .mega__grid.c3 {
    grid-template-columns: auto auto auto;
  }

  .mega-menu .mega__grid.c2 {
    grid-template-columns: auto auto;
  }

  .mega-menu .mega {
    top: calc(100% - 15px);
    background-color: var(--color-bloc) !important;
    border-radius: 0px;
    color: var(--color-primary) !important;
    min-width: 240px;
    padding: 25px 0;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .mega-menu .icon-chevron-sm-down {
    display: none;
  }
  .mega-menu__nav > li .mega::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: var(--color-bloc) !important;
    transform: rotate(45deg);
  }

  .mega-menu a {
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
  }

  .mega-menu .mega a:not(.button-primary) {
    padding: 5px 15px;
    border-radius: 0px;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    background-color: transparent !important;
    color: var(--color-primary) !important;
    line-height: 1.2;
    display: inline-block;
  }

  .mega-menu .mega .n1 a {
    padding: 10px 20px 10px 15px;
    border-radius: 0px;
    color: var(--color-ocre-dark) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
  }

  .mega-menu .mega .n1 a:hover {
    color: var(--color-white) !important;
    cursor: pointer;
    background-color: var(--color-ocre-dark) !important;
  }

  .mega-menu .mega .n2 a {
    padding: 8px 15px 8px 15px;
    font-size: 15px;
  }

  .mega-menu .mega .n2 a:hover {
    cursor: pointer;
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }

  .mega-menu .subnav > li.n2 a {
    padding: 5px 15px 8px 15px;
    font-size: 15px;
  }

  .mega-menu .mega .n1 a:hover {
  }

  .mega-menu .mega .n3 a {
    padding: 5px 20px 5px 15px;
    font-size: 15px;
    border-radius: 0px;
    color: var(--color-primary-3) !important;
    cursor: pointer;
    background: none;
    transition: 0.3s;
    line-height: 1.3;
    background-color: transparent !important;
    text-transform: none;
  }

  .mega-menu .accordion.voirplus .accordion-button {
    padding: 5px 10px;
    font-size: 15px;
  }

  .mega-menu .accordion.voirplus .accordion-button::after {
    width: auto;
    height: auto;
    background-image: none;
    font-family: "uicons-bold-straight" !important;
    content: "\f150";
    font-size: 12px;
    margin-left: 5px;
    color: var(--color-light-1) !important;
  }

  // .mega-menu .mega__item {
  //   overflow-wrap: anywhere;
  // }

  .mega__item p.collapse:not(.show) {
    height: 42px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .mega__item p.collapsing {
    min-height: 42px !important;
  }

  .mega-menu a.voirplus:after {
    content: "+ Voir plus";
    color: var(--color-light-1) !important;
  }

  .mega-menu a.voirplus:not(.collapsed):after {
    content: "- Voir moins";
    color: var(--color-light-1) !important;
  }

  .mega-menu a.voirplus {
    padding: 5px;
    font-size: 14px;
    color: var(--color-light-1) !important;
    text-decoration: underline;
    text-transform: none;
  }

  .mega-menu a.voirplus[aria-expanded="false"]:after {
    content: "+ Voir plus";
    color: var(--color-light-1) !important;
  }

  .mega-menu .mega a.voirplus:not(.button-primary) {
    color: var(--color-light-1) !important;
  }

  .mega__item .accordion-body {
    padding: 0;
  }

  .mega-menu .mega .n3 a:hover {
    color: var(--color-primary) !important;
  }
  .informatif .texte-punchline .container {
    max-width: 1100px;
  }
}

// XXX-Large devices (large desktops, more than 2000px)
@media screen and (min-width: 2000px) {
  footer .container.contenu {
    background-color: var(--color-ocre);
    margin-top: 140px;
  }
}

// X-Large devices (large desktops, more than 1400px)
@media screen and (min-width: 1400px) {
  // .encart {
  //   margin-top: -275px;
  // }
  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none !important;
  }
}

/******* MAX ********/

// X-Large devices (large desktops, less than 1400px)
@media screen and (max-width: 1399.98px) {
  .encart .w-360 {
    width: auto;
  }
  .encart .ml-n10 {
    margin-left: -10px !important;
  }
}

// Large devices (desktops, less than 1200px)
@media screen and (max-width: 1199.98px) {
  .header .header-menu .menu .subnav a {
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    line-height: 40px;
  }
  .header.is-menu-opened {
    background-color: var(--color-primary) !important;
    border-bottom: 1px solid var(--color-primary-3);
  }
  .footer {
    padding-top: 120px;
  }
  .header {
    height: 80px;
  }
  .header .header-menu__content {
    background: var(--color-primary) !important;
  }
  .header.is-menu-opened {
    background-color: var(--color-primary) !important;
    border-bottom: 1px solid var(--color-primary-3);
  }

  // .header .header-menu .menu .subnav li.menu-item-has-children:hover {
  //   background-color: transparent !important;
  //   background: none !important;
  // }
  .header .header-menu .menu__nav > li > a:hover {
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
    border-radius: 0;
    font-size: inherit;
  }
  .header .header-menu .menu li a {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header .header-menu .menu li a:hover {
    border-radius: 0;
    font-size: 20px;
  }

  .header .header-menu .menu .subnav li {
    padding-left: 20px !important;
  }

  .header .header-menu .menu .subnav > li a {
    color: var(--color-ocre-dark) !important;
  }

  .header .header-menu .menu .subnav > li a:hover {
    background: var(--color-ocre-dark) !important;
    color: #ebe6d5 !important;
    font-size: 18px;
  }

  .header .header-menu .menu .subnav li.menu-item-has-children {
    padding-left: 40px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li {
    padding-left: 40px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li a {
    color: var(--color-primary-2) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: block;
  }
  // .header .header-menu .menu .subnav li.menu-item-has-children a {
  //   color: var(--color-primary-2) !important;
  // }
  .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
    font-size: 18px;
  }

  // .header .header-menu .menu li:hover a {
  //   background-color: transparent !important;
  //   color: var(--color-primary) !important;
  // }

  // .header .header-menu .menu .subnav:hover li.menu-item-has-children {
  //   background-color: transparent !important;
  // }

  // .header
  //   .header-menu.is-menu-active
  //   .-is-active:hover
  //   li.menu-item-has-children {
  //   background-color: transparent !important;
  // }

  // .header
  //   .header-menu
  //   .menu
  //   .subnav
  //   li.menu-item-has-children
  //   .subnav
  //   li:hover {
  //   background-color: transparent !important;
  // }

  // .header
  //   .header-menu
  //   .menu
  //   .subnav
  //   li.menu-item-has-children
  //   .subnav
  //   li:hover
  //   a {
  //   background-color: transparent !important;
  // }

  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li a:hover {
    background-color: var(--color-primary-3) !important;
    color: var(--color-white) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a {
    color: var(--color-white) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child a:hover {
    color: var(--color-primary) !important;
    font-size: 20px;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children a:hover {
    color: var(--color-primary) !important;
  }
  .header .header-menu .menu .subnav li.menu-item-has-children .subnav li:first-child {
    padding-left: 20px;
  }
  .header .header-menu .menu .subnav__backBtn a {
    color: var(--color-white);
    background-color: transparent !important;
  }
  .header .header-menu .menu .subnav__backBtn a:hover {
    background-color: var(--color-primary-2) !important;
    color: var(--color-primary) !important;
    border-radius: 0;
    font-size: 20px;
  }
  #sejour.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 150px 220px 220px auto;
  }
  // #bafa.mainSearch .button-grid {
  //   display: grid;
  //   grid-template-columns: 1fr 220px 150px 220px auto;
  // }
  #bafa.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 250px 250px auto;
  }
  #educform.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 260px 150px auto;
  }
  #thematique.mainSearch .button-grid {
    display: grid;
    grid-template-columns: 1fr 180px 250px auto;
  }
  a.button-primary.secteur {
    border-radius: 0;
    max-width: 300px;
    position: fixed;
    top: 10px;
    z-index: 999;
    margin-top: 70px !important;
  }
  .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 220px;
  }

  .recherche.multi .tabs {
    margin-top: 70px !important;
    padding-left: 160px;
  }
  .equipe .child.libelle-region {
    margin-left: 40px;
  }
  main.actualites .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 220px;
  }

  .accueil .recherche.multi .tabs {
    margin-top: 70px !important;
    padding-left: inherit;
  }

  .recherche.thematique .tabs {
    margin-top: 90px !important;
    padding-left: 160px;
  }
  .recherche .container {
    position: relative;
    max-width: fit-content;
  }
  .recherche.multi .container {
    position: relative;
    max-width: 960px;
  }
  .navhead {
  }

  .navscroll.recherche {
    top: 0;
  }
  // .encart {
  //   margin-top: -390px;
  // }
  // .encart.educform {
  //   margin-top: -290px;
  // }
  .tabs a.link {
    margin: 10px;
  }

  .mega-menu.recherche .mega__grid {
    display: grid;
    gap: 40px 20px;
    grid-template-columns: auto auto auto;
  }
  // .mega-menu.recherche.v2 {
  //   margin-top: -140px;
  // }
  main.actualites .recherche .container {
    position: relative;
    max-width: 960px;
    padding-top: 40px;
  }
  .informatif .texte-punchline .container {
    max-width: 960px;
  }
  .informatif .texte-colonne .parent .child {
    // min-width: 300px;
    min-width: 100%;
  }
  .informatif .texte-galerie .parent:has(.child.tiny) {
    gap: 40px;
    justify-content: start;
  }
}

// Medium devices (tablets, less than 992px)
@media screen and (max-width: 991.98px) {
  .mainSearch {
    border-radius: 0px !important;
  }
  .accueil .mainSearch {
    width: calc(100% - 20px);
    border-radius: 0px !important;
    margin: 10px;
  }
  section.aroevens.equipe h5.selection {
    width: 100%;
    max-width: initial;
  }
  .equipe .child.libelle-region {
    margin-left: 0;
  }
  .mainSearch__submit {
    border-radius: 100px !important;
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 700;
    color: var(--color-white) !important;
    background-color: var(--color-primary);
    padding: 10px 20px;
    transition: 0.3s;
    white-space: nowrap;
    text-align: center;
  }
  .header .header__container .container {
    padding: 10px;
  }
  .enseignant .mainSearch {
    border-radius: 100px !important;
  }

  .enseignant .mainSearch__submit {
    border-radius: 100px !important;
    padding: 10px 15px;
  }

  .footer {
    padding-top: 80px;
  }
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr;
  }

  .galleryGrid.-type-1.gallery1 {
    grid-template-columns: 1fr;
  }
  .galleryGrid.-type-1 > *:nth-child(2) {
    grid-column: auto;
  }
  .encart-1 {
    margin-bottom: 0px;
  }
  .encart {
    position: relative;
    top: 0;
    margin-top: auto;
    height: auto;
    width: 100%;
    right: auto;
  }
  #sejour.mainSearch .button-grid {
    display: block;
  }
  #bafa.mainSearch .button-grid {
    display: block;
  }
  #educform.mainSearch .button-grid {
    display: block;
  }
  #thematique.mainSearch .button-grid {
    display: block;
  }
  #multi.mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
  #actualites.mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
  .navscroll {
    display: none;
  }
  .content.unitaire .toggle .button.masquer {
    margin-top: 40px;
  }
  .content.unitaire #resultats-filtres {
    transition: 0.3s ease;
    position: relative;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-right: 0;
  }
  #toggle-carte.button.masquer {
    display: none;
  }
  // .encart.educform {
  //   margin-top: auto;
  // }

  .galleryGrid.-type-1.grid-educform > *:nth-child(2),
  .galleryGrid.-type-1.grid-scolaireactivite > *:nth-child(2),
  .galleryGrid.-type-1.grid-actualite > *:nth-child(2) {
    grid-column: 2 span;
  }

  .galleryGrid.gallery4 > *:nth-child(4) {
    grid-column: 2 span;
  }
  // .tabs a.link,
  // .tabs a.link.active,
  // .tabs a.link.all {
  //   line-height: 13px;
  //   height: 45px;
  // }
  .recherche .tabs {
    padding-left: 0px;
    padding-top: 40px;
  }
  .recherche .tabs a.link {
    font-size: 15px;
    margin: 8px;
  }
  .recherche.multi .container,
  .recherche .container {
    position: relative;
    max-width: fit-content;
    padding-top: 0px;
  }
  .recherche.multi .tabs,
  .recherche.tabs {
    margin-top: 70px !important;
    padding-left: 0;
  }
  main.actualites .recherche .tabs {
    margin-top: 70px !important;
    padding-left: 0px;
    max-width: 960px;
  }

  main.actualites .recherche .container {
    position: relative;
    padding-top: 40px;
    max-width: 720px;
  }

  .processus-reservation .steps .button i[class*=" fi-ss-"]:before {
    vertical-align: middle;
    top: 0;
    position: relative;
    font-size: 20px;
  }
  // .processus-reservation .encart-1 {
  //   margin-bottom: 0px;
  //   width: 100% !important;
  // }
  .encart-1 {
    margin-bottom: 0px;
    width: 100% !important;
  }
  .encart-2 {
    margin-bottom: 0px;
    width: 100% !important;
  }

  .recrutement .recherche .container {
    max-width: 720px;
  }

  .accueil section.temoignages {
    padding-bottom: 300px;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 20px 20px 0px;
    position: relative;
  }

  .enseignant .blob {
    width: auto;
    max-width: 500px;
    min-width: 360px;
  }

  .accueil .search {
    position: relative;
  }

  .mega-menu.recherche {
    display: none;
  }
  // .accueil .heading .carousel-item:not(:first-child) {
  //   display: none !important;
  // }
  .accueil .heading .carousel-control-prev,
  .accueil .heading .carousel-control-next {
    display: none !important;
  }
  .accueil .recherche.multi .tabs {
    margin-top: 30px !important;
    padding-left: 0;
  }
  .accueil .heading .carousel-inner.full .carousel-item {
    // max-height: fit-content;
  }
  // .accueil .carousel-item video {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 99;
  //   object-fit: cover;
  // }

  .accueil .heading .carousel-inner.full .carousel-item img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 150vh;
  }
  .accueil #hero-video,
  .accueil .hero-video {
    height: 150vh;
    width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .accueil .slogan {
    margin: 0;
    z-index: 9;
    position: relative;
  }
  .accueil .recherche.multi {
    z-index: 9;
    position: relative;
    padding-bottom: 0;
  }
  .accueil .heading .slogan .header-logo img {
    width: calc(100% - 40px);
  }
  .accueil .recherche.multi .container,
  .accueil .recherche .container {
    padding-bottom: 100px;
  }

  .accueil .carousel .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150vh;
    width: 100vw;
    object-fit: cover;
    z-index: 1;
  }

  .parallax {
    background-attachment: scroll !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .accueil section.secteur1.bafa {
    background-attachment: scroll !important;
  }

  .accueil section.secteur1.educform {
    background-attachment: scroll !important;
  }

  .accueil section.secteur3.bafa {
    background-attachment: scroll !important;
  }

  .accueil section.secteur3.educform {
    background-attachment: scroll !important;
  }

  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 33%;
  }
  .select__dropdown.-is-visible {
    position: relative;
  }
  .select.mini .select__dropdown.-is-visible {
    position: absolute;
  }
  .accueil .heading .carousel-inner {
    overflow: hidden;
  }

  .accueil .heading .carousel-inner .carousel-item {
    overflow: initial !important;
  }
  .informatif .texte-punchline .container {
    max-width: 720px;
  }
  .bloc-login .footer {
    margin-top: -300px;
  }
  .tri {
    padding-right: 0;
  }
  .container.sous-pages .colonnes-2 {
    -webkit-column-count: 1; /* Chrome/Opera, Safari */
    -moz-column-count: 1; /* Mozilla Firefox */
    column-count: 1;
  }
}

@media screen and (max-height: 900px) {
  .encart {
    position: relative;
    top: 0;
  }
}

// Small devices (landscape phones, less than 768px)
@media screen and (max-width: 767.98px) {
  .container,
  .container-sm {
    max-width: 640px;
  }
  .header .header-logo img {
    max-width: 100px;
  }
  .searchbar.container.slogan {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  // .accueil .heading .carousel-inner.full .carousel-item:first-child {
  //   display: block !important;
  // }
  // .accueil .heading .carousel-inner.full .carousel-item:not(:first-child) {
  //   display: none !important;
  // }
  .suggestions h4 {
    margin-bottom: 20px;
  }
  .encart-1 {
    margin-bottom: 30px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 38px;
  }
  .etape-line {
    display: none;
  }

  .recrutement .recherche .container {
    max-width: 640px;
  }

  .suggestions.contact {
    text-align: center;
  }

  .suggestions.contact h2 {
    text-align: center;
  }

  .suggestions.contact h3 {
    text-align: center;
  }

  .suggestions.contact h4 {
    text-align: center;
  }
  .processus-reservation .connexion {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .galleryGrid.gallery4 > *:nth-child(1) {
    grid-column: 2 span;
  }
  .accueil .footer {
    padding-top: 60px;
  }
  .informatif .telechargements .child {
    flex: 1 0 51%;
  }
  .hebergements li.nav-item {
    width: 100%;
  }
  #map {
    clip-path: ellipse(150% 55% at 48% 44%);
  }
  .cartefrance {
    min-width: initial;
    width: calc(100% - 20px);
  }
  .enseignant .nav-item {
    width: 100%;
  }

  .enseignant .nav-item .nav-link {
    width: 100%;
  }
  .bloc-login .footer {
    margin-top: -400px;
  }
  .enseignant-image,
  .confirmation-image {
    display: none;
  }
  .bloc-login {
    align-items: start !important;
    padding-top: 120px !important;
    padding-bottom: 300px !important;
    min-height: initial;
  }
  .enseignant .bloc-login {
    align-items: start !important;
    padding-top: 60px !important;
    padding-bottom: 200px !important;
    min-height: initial;
    height: auto;
  }
  .accueil .titre h3 {
    font-size: 40px;
    line-height: 46px;
  }
  .accueil section.enseignant::before {
    width: 150%;
    left: -15%;
  }
  .accueil section.enseignant::after {
    width: 150%;
    left: -15%;
  }
  .accueil section.sejour::before {
    width: 150%;
    left: -15%;
  }
  .accueil section.sejour::after {
    width: 150%;
    left: -15%;
  }
  .curve-before {
    width: 150% !important;
    left: -25% !important;
  }
  .curve-after {
    left: -25%;
    width: 150%;
  }
  .curve-after-invert {
    left: -25%;
    width: 150%;
  }
  .informatif .texte-galerie .parent {
    justify-content: center;
  }
  .accueil .testimonials {
    font-size: 18px;
    line-height: 22px;
  }

  .accueil .testimonials.slogan {
    font-size: 22px;
    line-height: 26px;
    padding-top: 20px;
  }
  .informatif .texte-galerie .parent:has(.child.tiny) {
    justify-content: center;
  }
}

@media screen and (max-height: 800px) {
  .accueil .heading .carousel-inner.full .carousel-item img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 200vh;
  }
  .accueil #hero-video,
  .accueil .hero-video {
    height: 200vh;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 575.98px) {
  main > section:last-of-type {
    padding-bottom: 250px !important;
  }
  section.breadcrumb,
  section.heading,
  section.content {
    // padding-left: 20px;
    // padding-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  // .accueil .heading .carousel-inner.full .carousel-item.video {
  //   display: none;
  // }

  .swiper-bullets {
    display: none;
  }

  .no-page__title {
    font-size: 80px;
    font-weight: 600;
    line-height: 1.2;
  }

  .accueil section.content {
    padding-left: 0px;
    padding-right: 0px;
  }

  section.heading .curve {
    // width: calc(100% + 40px);
    // margin-left: -20px;
  }

  footer .container {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
  }

  .content .button-outline,
  .content .button-primary,
  .content .button-secondary {
    width: 100%;
    display: block;
  }

  .col-auto.option {
    width: 100%;
    max-width: 100%;
  }
  .departs .col-auto {
    width: 100%;
    max-width: 100%;
  }
  .departs .depart {
    background-position: 0 -50px;
  }
  .galleryGrid.-type-1 {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
  .galleryGrid.-type-1 img {
    height: 300px;
  }
  .galleryGrid.gallery4 > *:nth-child(1) {
    grid-column: auto;
  }
  .galleryGrid.gallery4 > *:nth-child(4) {
    grid-column: auto;
  }
  .accueil a.button-primary {
    width: 100%;
  }
  .accueil section.suggestions,
  .accueil section.bafa,
  .accueil section.educform,
  .accueil section.enseignant,
  .accueil section.equipe,
  .accueil section.actualites,
  .accueil section.posts,
  .accueil section.recrutement,
  .accueil section.temoignages {
    padding-left: 20px;
    padding-right: 20px;
  }

  .accueil .wrap {
    margin: 0px;
  }

  .accueil .pagination i:before {
    font-size: 16px;
  }

  .accueil .pagination.-avatars .pagination__item::before {
    width: 30px;
    height: 30px;
    margin-top: -1px;
  }

  .blob {
    height: 400px;
  }

  .accueil .blob {
    max-height: 400px;
    max-width: none !important;
    min-width: auto !important;
    aspect-ratio: 4 / 5;
    height: auto;
    width: calc(100% + 30px) !important;
  }
  .blob-back {
    min-width: 400px;
    min-height: 400px;
  }
  .blob-back-enseignant {
    min-width: auto;
    min-height: auto;
  }
  section.aroevens.equipe h5 {
    width: 100%;
  }
  .accueil .enseignant .bubble.conventionnement {
    min-width: auto;
    min-height: auto;
    width: auto;
    height: auto;
    aspect-ratio: 1 / 1;
    max-width: 400px;
  }
  .accueil .enseignant .bubble.conventionnement .titre h4 {
    color: #ecd1a0 !important;
    font-size: 20px;
    line-height: 22px;
  }
  .accueil section.heading {
    padding-left: 0px;
    padding-right: 0px;
  }
  section.equipe .swiper-slide {
    background-color: transparent;
    width: 160px !important;
  }

  .informatif .blob {
    min-width: initial;
  }

  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 50%;
  }
  .accueil section.sejour::before {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }

  .accueil section.sejour::after {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }

  .accueil section.enseignant::before {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }

  .accueil section.enseignant::after {
    transform: translate(-9%, -100%);
    -ms-transform: translate(-9%, -100%);
    -webkit-transform: translate(-9%, -100%);
  }

  img.logos-cb {
    max-width: 100%;
    height: auto;
    margin: 10px 0 20px 0px;
  }

  .galleryGrid__item:not(:first-child) {
    display: none;
  }

  .sous-pages .liste-simple a.link,
  .sous-pages h5 {
    // background: none;
  }
  footer .container.contenu {
    margin-top: 30px;
  }
  .accueil .searchbar h1 {
    line-height: 50px;
    margin-top: 0px !important;
    font-size: 40px;
  }
  .accueil .slogan {
    margin: 20px 0 0 0;
  }
  .informatif.contact .texte-galerie .parent:has(.child) {
    gap: 30px;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .informatif .texte-galerie .child {
    flex-basis: 100%;
    flex: 0 0 100%;
  }
  .header-logo.hero .baseline {
    font-size: 26px;
    width: calc(100% - 40px);
    line-height: 26px;
  }
  .header-logo.hero.federation .baseline {
    font-size: 30px;
    width: calc(100% - 40px);
  }
  .actions-header .enregistrer {
    margin-right: 0 !important;
  }
  .informatif .texte-colonne .parent .child {
    min-width: initial;
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .contenu-prestation {
    padding-right: 40px;
  }
  .titre-prestation {
    padding-right: 40px;
  }
}
