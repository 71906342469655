.mainSearch {
  position: relative;
  z-index: 20;
  
  @include media-below(lg) {
    width: 100%;
    border-radius: 4px !important;
  }

  .button-grid {
    display: grid;
    grid-template-columns: 1fr 250px 290px auto;

    > * + * {
      border-left: 1px solid var(--color-border);
    }
    
    @include media-below(lg) {
      grid-template-columns: 1fr;
      
      > * + * {
        border-left: 0;
        border-top: 1px solid var(--color-border);
      }
    }
  }
  
  .content-item {}

  .button-item {
    border: none !important;
  }

  &__submit {
    @include media-below(lg) {
      border-radius: 4px !important;
    }
  }

  &.-col-2 {
    .button-grid {
      grid-template-columns: 1fr 1fr min-content;

      @include media-below(lg) {
        grid-template-columns: 1fr;
      }
    }
  }

  &.-col-4 {
    .button-grid {
      grid-template-columns: auto auto 200px 200px min-content;

      @include media-below(lg) {
        grid-template-columns: 1fr;
      }
    }
  }

  &.-col-3-big {
    .button-grid {
      grid-template-columns: 1fr 0.6fr 0.6fr auto;

      @include media-below(lg) {
        grid-template-columns: 1fr;
      }
    }
  }

  &.-col-5 {
    .button-grid {
      grid-template-columns: auto auto auto auto auto min-content;

      @include media-below(lg) {
        grid-template-columns: 1fr;
        
        > * + * {
          border-left: 0;
          border-top: 1px solid var(--color-border);
        }
      }
    }
  }

  &.-w-900 {
    width: 900px;

    @include media-below(lg) {
      width: auto;
    }
  }

  &.-w-1070 {
    width: 1070px;
    max-width: 100%;

    @include media-below(lg) {
      width: auto;
    }
  }
}

.searchMenu-loc {
  position: relative;

  &__field {
    position: absolute;
    margin-top: 30px;
    top: 100%;
    left: 0;
    z-index: 20;
    width: 500px;
    max-width: 100vw;
    transition: all 0.2s $easeOutQuart;
    pointer-events: none;
    opacity: 0;

    @include media-below(lg) {
      width: auto;
      left: -20px;
      right: -20px;
    }
    
    @include media-below(sm) {
      left: -35px;
      right: -35px;
    }

    &.-is-active {
      pointer-events: auto;
      opacity: 1;
    }
  }
  
  .-link {
    &:hover {
      background-color: color-opac(blue-1, 0.05) !important;
    }
  }
}

.searchMenu-date {
  position: relative;

  &.-left {
    .searchMenu-date__field { transform: none !important; }
  }

  &.-right {
    .searchMenu-date__field {
      left: unset;
      right: 0;
      transform: none !important;
    }
  }

  &__field {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    width: 900px;
    max-width: 100vw;
    margin-top: 30px;
    box-shadow: 0px 10px 60px 0px #0510360D;
    transform: translateX(-31%);
    transition: all 0.2s $easeOutQuart;
    pointer-events: none;
    opacity: 0;

    &.-is-active {
      pointer-events: auto;
      opacity: 1;
    }
    
    @include media-below(lg) {
      transform: none;
      width: auto;
      left: -20px;
      right: -20px;
    }
    
    @include media-below(sm) {
      left: -35px;
      right: -35px;
    }
  }
}

.searchMenu-guests {
  position: relative;

  &__field {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    width: 400px;
    max-width: 100vw;
    margin-top: 30px;
    box-shadow: 0px 10px 60px 0px #0510360D;
    transition: all 0.2s $easeOutQuart;
    pointer-events: none;
    opacity: 0;
    
    @include media-below(lg) {
      width: auto;
      left: -20px;
      right: -20px;
    }
    
    @include media-below(sm) {
      left: -35px;
      right: -35px;
    }

    &.-is-active {
      pointer-events: auto;
      opacity: 1;
    }
  }
}
