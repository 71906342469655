:root {
  --dashboard-width: 300px;
}

@include media-below(xl) {
  :root {
    --dashboard-width: 250px;
  }
}

.dashboard {
  display: flex;

  @include media-below(lg) {
    flex-direction: column;
  }

  &__main {
    overflow: hidden;
    width: 100%;
    padding-left: var(--dashboard-width);
    will-change: padding-left;
    transition: all 0.5s $easeOutCubic;

    &::after {
      content: "";
      display: none;
      position: fixed;
      z-index: 50;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(black, 0.5);

      transition: all 0.5s $easeOutCubic;
      pointer-events: none;
      opacity: 0;
    }

    @include media-below(lg) {
      padding-left: 0;

      &::after {
        display: block;
      }
    }
  }
  
  &__content {
    width: 100%;
    padding: 60px;
    padding-bottom: 0;
    
    @include media-below(md) {
      padding: 30px;
      padding-bottom: 0;
    }
    
    @include media-below(sm) {
      padding: 20px;
      padding-bottom: 0;
    }
  }
  
  &__sidebar {
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: var(--dashboard-width);
    height: calc(100% - 90px);
    padding: 30px;
    padding-top: 60px;
    padding-bottom: 40px;
    will-change: transform;
    transition: all 0.5s $easeOutCubic;
    
    @include media-below(lg) {
      transform: translateX(-100%);
    }
    
    @include media-below(md) {
      width: 80%;
      height: calc(100% - 80px);
    }
  }

  &.-is-sidebar-open {
    @include media-below(lg) {
      .dashboard__sidebar {
        transform: none;
      }
  
      .dashboard__main {
        &::after {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }

    @include media-above(lg) {
      .dashboard__sidebar {
        transform: translateX(-100%);
      }
  
      .dashboard__main {
        padding-left: 0;

        &::after {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
}


.sidebar.-dashboard {
  .sidebar {
    &__item {
      border: none;
    }

    &__button {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0 15px;
      height: 55px;
      
      @include media-below(sm) {
        height: 45px;
      }

      &.-is-active {
        background-color: color-opac(blue-1, .05);
        color: var(--color-blue-1) !important;
      }
    }
  }
}


.scroll-bar-1 {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(black, 0.3);
    border-radius: 12px;
  }
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  
  &__button {
    height: 40px;
  }

  .icon {
    transition: all 0.2s $easeOutQuart;
  }

  &.-is-dd-active {
    .border-light {
      border: 2px solid var(--color-dark-1) !important;
    }

    .icon {
      transform: rotate(180deg);
    }
  }
  
  &__item {
    position: absolute;
    z-index: 500;
    pointer-events: none;
    top: 100%;
    left: 50%;
    padding: 26px;
    background-color: white;
    box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
    min-width: 180px;
    border-radius: 8px;
    transition: all 0.2s $easeOutQuart;
    transform: translateX(-50%);
    opacity: 0;
  }

  &:hover {
    .dropdown__item {
      z-index: 20;
      transform: translateX(-50%);
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.toggle-element {
  transition: all 0.2s $easeOutCubic;
  opacity: 0;
  pointer-events: none;

  &.-down {
    transform: translateY(20px);
  }
  
  &.-is-el-visible {
    z-index: 25;
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }

  &.-dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    padding: 14px 20px;
    background-color: white;
    box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
    min-width: 160px;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    font-weight: 500;
    transition: all 0.2s $easeOutQuart;
    transform: translateX(-50%);
  }

  &.-dropdown-2 {
    position: absolute;
    top: 100%;
    left: 50%;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
    min-width: 120px;
    border-radius: 4px;
    transition: all 0.2s $easeOutQuart;
    transform: translateX(-50%);

    a {
      padding: 10px;

      &:hover {
        background-color: var(--color-light-2);
        color: var(--color-dark-1);
      }
    }
  }
}
