button {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;

  &:focus {
    outline: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s $easeOutQuart;
}

.button {
  &.-sm {
  }

  &.-md {
    padding: 14px 30px;
  }
  
  &.-lg {
    padding: 19px 30px;
  }

  &.-min-180 { min-width: 180px; }

  &.-underline:hover {
    text-decoration: underline;
  }
}

@each $name, $color in $colors {
  .button.-#{$name} {
    &:hover {
      border-color: var(--color-#{$name});
      background-color: var(--color-#{$name}) !important;
      color: white !important;
    }
  }

  .button.-outline-#{$name} {
    border-color: var(--color-#{$name});

    &:hover {
      background-color: var(--color-#{$name});
      border-color: transparent;
      color: white !important;
    }
  }
}

.button.-white {
  &:hover {
    background-color: var(--color-light-3) !important;
    color: var(--color-dark-1) !important;
  }
}

.button.-blue-1-05 {
  &:hover {
    background-color: color-opac(blue-1, .05) !important;
    color: var(--color-blue-1) !important;
  }
}

.button.-outline-white {
  &:hover {
    background-color: white !important;
    color: var(--color-dark-1) !important;
  }
}
